import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { useOpenId } from "./OpenIdConnectContextProvider";
import { onUpdateOdicDomain, resetOdicDomain } from "../../slices/authentication";
import { 
    Box, 
    Typography, 
    Button, 
    IconButton, 
    Grid,
    Container
} from '@mui/material';

import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: 20,
        fontWeight: 900,
        color: '#474747',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        marginTop: 30,
        WebkitTextStroke: '1px #474747',
        textStroke: '1px #474747',
        textShadow: '0 0 1px #474747, 0 0 1px #474747',
        letterSpacing: '2px',
    },
    subtitle: {
        fontSize: 20,
        fontWeight: theme.typography.fontWeightBold,
        color: '#395a64',
        WebkitTextStroke: '1px #395a64',
        textStroke: '1px #395a64',
        textShadow: '0 0 1px #395a64, 0 0 1px #395a64',
    },
    backButton: {
        backgroundColor: '#f3f3f3',
        borderColor: '#fafafa',
        textTransform: 'none',
        fontSize: '16px'
    },
    content: {
        '&.MuiGrid-container': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: "nowrap",
            paddingBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
            },
        },
    },
}))

const OpenIdLogout = ({history, odicDomain, resetOdicDomain}, context) => {

    /**
     * Logout Page which is a replica of the universal logout page and responsible for:
     * If Login was attempted through SSO
     * 1. Set Authority from the current 'odicDomain' state
     * 2. Stop Silent Renew
     * 3. Attempt removing current user
     * 4. Revoke active access token 
     * 
     * For other cases:
     * Redirect to Universal Logout Page at /logout
     */
    const classes = useStyles();
    const auth = useAuth()
    const { oidcConfig, OPENID_STATE, state, currentTenant, changeTenant, getConfigs } = useOpenId()

    useEffect(() => {
        console.log("OpenIdLogout: auth: ", auth, " user: ", auth.user, " odicDomain: ", odicDomain)
    }, [auth.user, odicDomain])

    useEffect(() => {
        if(odicDomain === ""){
            resetOdicDomain()
            const trigger = setTimeout(() => {
                console.log("OpenIdLogout: redirecting after removing user")
                history.push('/logout')
            }, 2 * 1000)
        } else {
            if(state !== OPENID_STATE.READY){
                changeTenant(odicDomain)
            }
            if(state === OPENID_STATE.READY && auth.user){
                if(auth && auth.user){
                   (async() => {
                    console.log("OpenIdLogout: localStorage: removing user")
                    await auth.stopSilentRenew()
                    await auth.removeUser()
                    await auth.revokeTokens()
                })()
                }        
                resetOdicDomain()
                const trigger = setTimeout(() => {
                    console.log("OpenIdLogout: redirecting after removing user")
                    history.push('/logout')
                }, 5 * 1000)
                
            }
        }

    }, [state, auth.user])

    const handleBack = () => {
        const currentLoggedOutUserType = sessionStorage.getItem('currentLoggedOutUserType');
        history.push(`/${currentLoggedOutUserType}`)
        sessionStorage.removeItem('currentLoggedOutUserType');
    }

    const handleClickLinkedIn = () => {
        window.location = 'https://www.linkedin.com/company/neufast/';
    }

    const BackButton = styled(Button)(({ theme }) => ({
        backgroundColor: '#f3f3f3',
        borderColor: '#fafafa',
        color: '#606567',
        textTransform: 'none',
        fontSize: '16px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#f3f3f3',
            borderColor: '#fafafa',
            color: '#606567',
        }
    }));
    
    return (
        <>
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px', marginBottom: '15vh', minWidth:'150px' }}>
                <img src={"/logo/Neufast_logo_horizontal.png"} alt="logout" style={{ width: '150px' }}></img>
            </Box>
            <Grid container className={classes.content}>
                <Grid item display={'flex'} justifyContent={'center'} direction={'column'}>
                    <Typography variant='h4' className={classes.subtitle} sx={{ marginBottom: '16px' }}>
                        {context.t("Logout_LoggedOut")}
                    </Typography>
                    <Typography sx={{ fontSize: '16px', color: '#131313' }}>
                        {context.t("Logout_QuestionsOrAssitance")}
                    </Typography>
                    <Typography sx={{ fontSize: '16px', marginBottom: '16px', color: '#131313' }}>
                        {context.t("Logout_FeelFreeTo")}<a href="mailto:customer-service@neufast.com" style={{ color: '#4388e2' }}>{context.t("Logout_ContactUs")}</a>.
                    </Typography>
                    <Typography sx={{ fontSize: '16px', marginBottom: '16px' }}>
                        {context.t("Logout_LetsConnect")}
                        <IconButton onClick={handleClickLinkedIn}>
                            <LinkedInIcon sx={{ color: '#022d41', fontSize: '20px' }} />
                        </IconButton>
                    </Typography>
                    <BackButton variant="contained" onClick={handleBack} sx={{width: 200}}>
                        {context.t("Logout_BackToHome")}<ArrowForwardIcon />
                    </BackButton>
                </Grid>
                <Grid item display={'flex'} justifyContent={'center'}>
                    <img src={"/images/logout_thankyou.png"} alt="thankyou" style={{ width: '60%'}}></img>
                </Grid>
            </Grid>
        </Container>
        </>
    )
}

const mapState = state => {
    return {
        odicDomain: state.authenticated.odicDomain,
    }
}

const mapDispatch = {
    onUpdateOdicDomain,
    resetOdicDomain
}

OpenIdLogout.contextTypes = {
    t: PropTypes.func
}

export default withRouter(connect(mapState, mapDispatch)(OpenIdLogout));