import React, {useState, useRef, createContext, useContext, useMemo } from "react";
import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProvider } from "react-oidc-context";

import { neufastTenant } from "./neufastTenant";
import { kbtgTenant } from "./kbtgTenant";

const OpenIdInstanceContext = createContext();

export const OPENID_STATE = {
    "INITIALIZE": "initialize",
    "LOADING": "loading",
    "READY": "ready"
}

/**
 * Object to set Default UserManagerSettings
 * that will be used across all openid authorities
 */
const defaultConfigs = {
    scope: "profile openid email",
    response_type: "code",
    onSigninCallback:  (_user) => {
        console.log("Auth System: user: ", _user)
    },
    monitorSession: true,
    monitorAnonymousSession: true,
    revokeTokensOnSignout: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
}

export const OpenIdProvider = ({children}) => {
    const oidcConfigRef = useRef(null)
    
    console.log("Auth System: Default Check: authority: useRef:", oidcConfigRef.current?.authority)

    /**
     * state is used to track the mounting of this provider
     * and the switching between different openid authorities
     * 
     * INITIALIZE = Initial mounting of the component
     * LOADING = Prearing to update openid tenant
     * READY = open id tenant is loaded in and ready to be used.
     */
    const [state, setState] = useState(OPENID_STATE.INITIALIZE)

    /**
     * currentTenantRef is used to denote the currently active
     * openid authority and the utility function switches
     * between the registered ones
     */
    const currentTenantRef = useRef("default")
    const setCurrentTenant = (value) => currentTenantRef.current = value

    if([null, undefined].includes(oidcConfigRef.current)){
        setState(prev => OPENID_STATE.LOADING)
        oidcConfigRef.current = {
            ...defaultConfigs,
            ...kbtgTenant,
        }
        console.log("Auth System: Default Check: currentTenantRef", currentTenantRef.current, " oidcConfigRef.current: ", oidcConfigRef.current?.authority)
        setCurrentTenant("kbtg")
    }

    const getConfigs = (tenantName) => {
        let newConfig;
        switch(tenantName){
            case 'kbtg':
                    newConfig = kbtgTenant;
                    setCurrentTenant(tenantName)
                    break;
            case 'sap':
                    newConfig = kbtgTenant;
                    setCurrentTenant(tenantName)
                    break;
            default:
                console.log("OpenIdProvider: defaulting to neufast tenant")
                newConfig = neufastTenant
                setCurrentTenant("neufast")
        }
        return newConfig;
    }

    const changeTenant = (tenantName) => {
        console.log("Auth System: LoginPage: changing tenant: ", tenantName)
        setState(prev => OPENID_STATE.LOADING)
        let newConfig = getConfigs(tenantName);
        oidcConfigRef.current = {
            ...defaultConfigs,
            ...newConfig,
        }
        console.log("Auth System: Default Check: changeTenant authority:", oidcConfigRef.current?.authority, " tenantName: ", tenantName)
        setState(prev => OPENID_STATE.READY)
    }

    useMemo(() => {
        console.log("Auth System: Default Check: authority: ", oidcConfigRef.current?.authority)
    }, [oidcConfigRef.current?.authority])

    /**
     * 'key' prop needs to be passed to re-render the AuthProvider component
     * when switching between different authorities
     */
    return useMemo(() => {
        console.log("Auth System: refreshed: oidcConfigRef.current: ", oidcConfigRef.current?.authority, " currentTenantRef: ", currentTenantRef.current, " domain: ", state)
        return (
            <>
            <OpenIdInstanceContext.Provider value={{
                oidcConfig: oidcConfigRef.current,
                OPENID_STATE,
                state,
                currentTenant: currentTenantRef.current,
                getConfigs,
                changeTenant,
            }}>
                <AuthProvider {...oidcConfigRef.current} key={currentTenantRef.current}>
                    {children}
                </AuthProvider>
            </OpenIdInstanceContext.Provider>
            </>

        )
    }, [oidcConfigRef.current])
}

/**
 * Hook to access OpenId state variables & utility functions
 * @returns
 */
export const useOpenId = () => {
    const context = useContext(OpenIdInstanceContext);
    if(!context){
        throw new Error('useOpenId must be used within an OpenIdProvider provider');
    }
    return context;
}