import { createSlice } from 'redux-starter-kit'
import { exportData, } from '../apis/statistics'
import { gets3object } from '../apis/s3access'
import {
    deleteCandidate as delCandidate,
    fetchScores,
    fetchCandidatesByReviewStatus,
    fetchCandidatesForManualReviewStatus,
    fetchScoresByGuest,
    fetchSetting,
    fetchSettingByGuest,
    importCandidate as importCan,
    importCandidateByJson,
    importCandidateByCV,
    getTask,
} from '../apis/recruiter'

import {
    importCandidateByAccessCode
} from '../apis/jobseeker'

import {
    getInfo,
    getInfoByGuest,
    getVideo,
    getVideoByGuest,
    retrieveComments,
    submitComments,
    getReviewerScoring,
    submitReviewerScoring,
    submitCommentsByGuest,
    getPdfReportApi,
    getPdfTrainingReportApi,
    getEmotionsApi,
    listS3FilesApi,
    transferS3FileApi,
    checkMultipleChoiceAnswersApi,
    fetchShortQuestionAnswerApi,
    checkShortQuestionAnswersApi,
    fetchAllCandidatesTotalScore,
} from "../apis/reports";
import config from '../config'
import axios from 'axios'
import assign from 'lodash/assign'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import size from 'lodash/size'
import path from 'path'
import { ContactSupportOutlined } from '@material-ui/icons';
import { BUCKETS } from '../constants/constants';

/**
 * @template
 * - candidateList & displayList: 
    [
        {
            name: Ada Leung;
            university: The University of Hong Kong (HKU);
            autoScore: number or "-";
            recruiterScore: number or "-";
            email: "test1@neufast.com";
            questionSet: 1;
            questionNum: 2;
            invitation: 0 or 1;
            reminder: 0 or 1;
            confirmation: 0 or 1;
        }
    ]
 * - stringFilter: Any String
 * - typeFilter: "auto_score", "recruiter_score"
 * - percentageFilter: 0 - 100
 
 */


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function desc(a, b, orderBy) {
    if (b[orderBy] === "-" && a[orderBy] !== "-") {
        return -1;
    }
    if (a[orderBy] === "-" && b[orderBy] !== "-") {
        return 1;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

let initialState = {
    loadedJobId: null, // jobId candidateList belongs to
    candidateList: [],
    displayList: [],
    importProgress: 0,
    currentViewingCandidate: {},
    currentViewingCandidateQuestions: null,
    currentViewingCandidateComment: null,
    currentViewingCandidateMultiQuestions: null,
    weighting: null,
    importRepeatedEmail: [],
    totalCandidateCount: 0,
    totalCandidateFilteredCount: 0,

    stringFilter: '',
    review_page_order: 'asc',
    review_page_orderBy: 'interviewId',

    pagination: {
        pageNum: 1,
        limit: 10,
        selectAll: false,
        fetching: false,
        filter: {
            searchTerm: "",
            statusFilter: 'All',
            percentageFilter: null,
            percentageFilterType: 'auto_score',
        },
        sort: {
            sortBy: 'id',
            order: 1,
        }
    },
    
    // for the selection of candidate in the candidate list
    selectedCandidates: {
        // interviewId1: {
        //     ...candidateDetails...
        // },
        // interviewId2: {
        //     ...candidateDetails...
        // },
    },

    loadingCandidateList: false,
    loadingCandidateProfile: false,
    loadingCandidateQuestions: false,
    deletingCandidate: false,
    importingCandidate: false,
    exportingCandidate: false,
    exportingCandidateError: null,
    submittingComment: false,
    retrievingComment: false,
    currentCandidateReviewerEvaluation: {},

    gettingPdfReport: false,
    gettingPdfTrainingReport: false,

    gettingEmotions: false,
    currentCandidateEmotions: {},

    listingS3Files: false,
    currentCandidateLocalFiles: null,
    currentCandidateS3Files: null,
    listS3FilesError: null,

    transferringS3Files: false,
    transferS3FilesCount: null,
    transferS3FilesFailures: null,
    transferS3FilesError: null,
};

const candidateListSlice = createSlice({
    slice: 'candidateList',
    initialState: initialState,
    reducers: {
        onLoadingCandidate(state, action) {
            state.loadingCandidateList = true
        },
        onLoadingCandidateSuccess(state, action) {
            const {
                jobId,
                candidateList,
                totalCandidateCount,
                totalCandidateFilteredCount
            } = action.payload;
            state.loadingCandidateList = false;
            state.loadedJobId = jobId;
            state.candidateList = candidateList;
            state.displayList = candidateList;
            state.totalCandidateCount = totalCandidateCount;
            state.totalCandidateFilteredCount = totalCandidateFilteredCount
        },
        onLoadingCandidateFailed(state, action) {
            state.loadingCandidateList = false
        },
        onLoadingCandidateProfile(state, action) {
            state.loadingCandidateProfile = true;
            state.currentViewingCandidate = {};
        },
        onLoadingCandidateProfileSuccess(state, action) {
            const profile = action.payload;
            state.currentViewingCandidate = profile;
            state.loadingCandidateProfile = false;
        },
        onLoadingCandidateProfileFailed(state, action) {
            state.loadingCandidateProfile = false
        },
        onDeletingCandidate(state, action) {
            state.deletingCandidate = true
        },
        onDeletingViewingCandidate(state, action) {
            state.currentViewingCandidate = {};
            state.currentViewingCandidateQuestions = null;
            state.currentViewingCandidateMultiQuestions = null;
        },
        onDeletingCandidateSuccess(state, action) {
            state.deletingCandidate = false
        },
        onDeletingCandidateFailed(state, action) {
            state.deletingCandidate = false
        },

        onImportingCandidate(state, action) {
            state.importingCandidate = true
        },
        onImportingCandidateSuccess(state, action) {
            state.importingCandidate = false
        },
        onImportingCandidateFailed(state, action) {
            state.importingCandidate = false
            state.importRepeatedEmail = action.payload.email ? action.payload.email : []
        },

        clearImportRepeatedEmail(state, action) {
            state.importRepeatedEmail = []
        },

        onExportingCandidate(state, action) {
            state.exportingCandidate = true
        },
        onExportingCandidateSuccess(state, action) {
            state.exportingCandidate = false
        },
        onExportingCandidateFailed(state, action) {
            state.exportingCandidate = false
            state.exportingCandidateError = action?.payload?.message
        },
        onFetchQuestions(state, action) {
            state.loadingCandidateQuestions = true;
        },
        onFetchQuestionsSuccess(state, action) {
            state.currentViewingCandidateQuestions = action.payload;
            state.loadingCandidateQuestions = false;
        },
        clearCandidateQuestion(state, action) {
            state.currentViewingCandidateQuestions = null
        },
        onFetchQuestionsFailed(state, action) {
            state.loadingCandidateQuestions = false;
        },
        clearCandidateMultiQuestion(state, action) {
            state.currentViewingCandidateMultiQuestions = null
        },
        search(state, action) {
            const {
                stringFilter,
                typeFilter,
                percentageFilter,
                statusFilter
            } = action.payload;

            state.displayList = stringFilter === "" ? state.candidateList : state.candidateList.filter((candidate) => candidate.name.toString().toLocaleLowerCase().includes(stringFilter.toLocaleLowerCase()) || candidate.university.toString().toLocaleLowerCase().includes(stringFilter.toLocaleLowerCase()));
            let tempList = [...state.displayList];
            if (percentageFilter !== null && percentageFilter !== 100) {
                if (typeFilter === 'auto_score') {
                    tempList = tempList.filter((candidate) => candidate.autoScore !== '-')
                    tempList = tempList.sort((a, b) => (a.autoScore < b.autoScore) ? 1 : ((b.autoScore < a.autoScore) ? -1 : 0))
                } else if (typeFilter === 'recruiter_score') {
                    tempList = tempList.filter((candidate) => candidate.recruiterScore !== '-')
                    tempList = tempList.sort((a, b) => (a.recruiterScore < b.recruiterScore) ? 1 : ((b.recruiterScore < a.recruiterScore) ? -1 : 0))
                }
                state.displayList = tempList.slice(0, Math.ceil(tempList.length * percentageFilter / 100))
            }
            switch (statusFilter) {
                case 'All':
                    break
                case 'Recruiter_Dashboard_TableColumn_Status_Shortlisted':
                    state.displayList = state.displayList.filter((candidate) => candidate.shortlisted === 1)
                    break
                case 'Recruiter_Dashboard_TableColumn_Status_Rejected':
                    state.displayList = state.displayList.filter((candidate) => candidate.shortlisted === -1)
                    break
                case 'Recruiter_Dashboard_TableColumn_Status_WaitingListed':
                    state.displayList = state.displayList.filter((candidate) => candidate.shortlisted === -2)
                    break
                case 'Recruiter_Dashboard_TableColumn_Status_Complete':
                    state.displayList = state.displayList.filter((candidate) => candidate.complete === 1 && candidate.shortlisted === 0 && candidate.reviewStatus === "reviewed")
                    break
                case 'Recruiter_Dashboard_TableColumn_Status_Partial_Complete':
                    state.displayList = state.displayList.filter((candidate) => candidate.complete === 0.5 && candidate.shortlisted === 0 && candidate.processing === 0)
                    break
                case "Recruiter_Dashboard_TableColumn_Status_Processing":
                    state.displayList = state.displayList.filter((candidate) => candidate.processing === 1 || candidate.reviewStatus === "under_review")
                    break
                case "Recruiter_Dashboard_TableColumn_Status_Wait_Invite":
                    state.displayList = state.displayList.filter((candidate) => candidate.invitation === 1 && candidate.complete === 0 && candidate.shortlisted === 0 && candidate.processing != 1)
                    break
                case "Recruiter_Dashboard_TableColumn_Status_Pending":
                    state.displayList = state.displayList.filter((candidate) => candidate.invitation === 0 && candidate.complete === 0 && candidate.shortlisted === 0 && candidate.reviewStatus === undefined)
                    break
                default:

            }
        },
        setPagination(state, action) {
            const {
              pageNum,
              limit,
              selectAll,
              fetching,
              filter,
              sort
            } = action.payload;
            const returnPagination = {
              ...state,
              pagination: {
                ...state.pagination,
                pageNum,
                limit,
                filter: {
                  ...state.pagination.filter,
                  searchTerm: filter.searchTerm !== undefined ? filter.searchTerm : state.pagination.filter.searchTerm,
                  statusFilter: filter.statusFilter || state.pagination.filter.statusFilter,
                  percentageFilter: filter.percentageFilter !== undefined ? filter.percentageFilter : state.pagination.filter.percentageFilter,
                  percentageFilterType: filter.percentageFilterType || state.pagination.filter.percentageFilterType,
                },
                sort: {
                  ...state.pagination.sort,
                  sortBy: sort.sortBy || state.pagination.sort.sortBy,
                  order: sort.order || state.pagination.sort.order,
                },
                selectAll: selectAll !== undefined ? selectAll : state.pagination.selectAll,
                fetching: fetching !== undefined ? fetching : true,
              }
            };
            return returnPagination;
        },   
        reviewer_search(state, action) {
            const {
                stringFilter,
            } = action.payload;
            state.stringFilter = stringFilter

            state.displayList = stringFilter === "" ? stableSort(state.candidateList, getSorting(state.review_page_order, state.review_page_orderBy)) : stableSort(state.candidateList, getSorting(state.review_page_order, state.review_page_orderBy)).filter((candidate) => (candidate.interviewId && candidate.interviewId.toLocaleLowerCase().includes(stringFilter.toLocaleLowerCase())) || (candidate.programName && candidate.programName.toLocaleLowerCase().includes(stringFilter.toLocaleLowerCase())) || (candidate.jobName && candidate.jobName.toLocaleLowerCase().includes(stringFilter.toLocaleLowerCase())));
        },
        reviewer_sort(state, action) {
            state.review_page_order = action.payload.order
            state.review_page_orderBy = action.payload.orderBy
            state.displayList = stableSort(state.displayList, getSorting(state.review_page_order, state.review_page_orderBy))
        },
        setCurrentViewingCandidate(state, action) {
            state.currentViewingCandidate = state.candidateList.filter((candidate) => candidate.email === action.payload.email)
        },
        onSubmitComment(state, action) {
            state.submittingComment = true;
        },
        onSubmitCommentSuccess(state, action) {
            state.submittingComment = false;
        },
        onSubmitCommentFailed(state, action) {
            state.submittingComment = false;
        },
        onRetrieveComment(state, action) {
            state.retrievingComment = true;
        },
        onRetrieveCommentSuccess(state, action) {
            state.retrievingComment = false;
            state.weighting = action.payload.weighting;
            state.currentViewingCandidateComment = action.payload.scoring;
        },
        onRetrieveCommentFailed(state, action) {
            state.retrievingComment = false;
        },
        clearProfile(state, action) {
            state.currentViewingCandidate = {
                name: "-",
                email: "-",
                degree: "-",
                university: "-"
            }
        },
        updateImportProgress(state, action) {
            state.importProgress = action.payload
        },
        onFetchReviewerEvaluation(state, action) {
            state.fetchingReviewerEvaluation = true
        },
        onFetchReviewerEvaluationSuccess(state, action) {
            state.fetchingReviewerEvaluation = false
            state.currentCandidateReviewerEvaluation = action.payload
        },
        onFetchReviewerEvaluationFailed(state, action) {
            state.fetchingReviewerEvaluation = false
        },
        // onSubmitReviewerEvaluation(state, action) {
        //     state.submittingReviewerEvaluation = true
        // },
        // onSubmitReviewerEvaluationSuccess(state, action) {
        //     state.submittingReviewerEvaluation = false
        // },
        // onSubmitReviewerEvaluationFailed(state, action) {
        //     state.submittingReviewerEvaluation = false
        // },

        onGettingPdfReport(state, action) {
            state.gettingPdfReport = true
        },
        onGettingPdfReportSuccess(state, action) {
            state.gettingPdfReport = false
        },
        onGettingPdfReportFailed(state, action) {
            state.gettingPdfReport = false
        },

        onGettingPdfTrainingReport(state, action) {
            state.gettingPdfTrainingReport = true
        },
        onGettingPdfTrainingReportSuccess(state, action) {
            state.gettingPdfTrainingReport = false
        },
        onGettingPdfTrainingReportFailed(state, action) {
            state.gettingPdfTrainingReport = false
        },

        onGettingEmotions(state, action) {
            state.gettingEmotions = true
        },
        onGettingEmotionsSuccess(state, action) {
            state.gettingEmotions = false
            state.currentCandidateEmotions = action.payload
        },
        onGettingEmotionsFailed(state, action) {
            state.gettingEmotions = false
        },

        onListingS3Files(state, action) {
            state.listingS3Files = true
            state.currentCandidateLocalFiles = null
            state.currentCandidateS3Files = null
            state.listS3FilesError = null
        },
        onListingS3FilesSuccess(state, action) {
            state.listingS3Files = false
            state.currentCandidateLocalFiles = get(action, 'payload.files', [])
            state.currentCandidateS3Files = get(action, 'payload.s3files', [])
        },
        onListingS3FilesFailed(state, action) {
            state.listingS3Files = false
            state.listS3FilesError = get(action, 'payload.message')
        },

        onTransferringS3Files(state, action) {
            state.transferringS3Files = true
            state.transferS3FilesCount = null
            state.transferS3FilesFailures = null
            state.transferS3FilesError = null
        },
        onTransferringS3FilesProgress(state, action) {
            state.transferringS3Files = true
            state.transferS3FilesCount = get(action, 'payload.transferS3FilesCount')
            state.transferS3FilesFailures = get(action, 'payload.transferS3FilesFailures')
        },
        onTransferringS3FilesSuccess(state, action) {
            state.transferringS3Files = false
            state.transferS3FilesCount = get(action, 'payload.transferS3FilesCount')
            state.transferS3FilesFailures = get(action, 'payload.transferS3FilesFailures')
        },
        onTransferringS3FilesFailed(state, action) {
            state.transferringS3Files = false
            state.transferS3FilesError = get(action, 'payload.message')
        },
        onFetchMultiQuestionSuccess(state, action) {
            state.currentViewingCandidateMultiQuestions = action.payload.flattenQuestions
            state.testType = action.payload.testType
        },
        onUpdatingShortQuestionResult(state, action){
            const {questionNum, questionTask, result, totalScore} = action.payload
            state.currentViewingCandidateMultiQuestions.forEach((multiQuestion, index) => {
                if (multiQuestion.task === questionTask){
                    state.currentViewingCandidateMultiQuestions[index].answer[questionNum].result = result
                    state.currentViewingCandidateMultiQuestions[index].total_score = totalScore
                }
            })
        },
        toggleSelectedCandidate(state, action) {
            const candidate = action.payload;
            const interviewId = candidate.interviewId;
            if (state.selectedCandidates && state.selectedCandidates[interviewId]) {
                delete state.selectedCandidates[interviewId];
            } else {
                state.selectedCandidates[interviewId] = candidate;
            }
            if(Object.keys(state.selectedCandidates).length !== state.totalFilteredCandidateCount){
                state.pagination.selectAll = false
                state.pagination.fetching = false
            }
        },
        toggleSelectAllCandidates: (state, action) => {
            const selectAll = action.payload;
            // Action payload should contain an array of all items on the current page
            let itemsOnPage = state.candidateList

            // // Check if all items on the page are already selected
            // const allSelected = itemsOnPage.every((item) => state.selectedCandidates[item.interviewId]);
            // If all selected, deselect all; otherwise, select all
            if (!selectAll) {
                state.pagination.selectAll = false;
                state.selectedCandidates = {};                
            } else {
                itemsOnPage.forEach((item) => {
                    state.selectedCandidates[item.interviewId] = item;
                });
            }
        },
        setSelectCandidates: (state, action) => {
            state.selectedCandidates = action.payload;
        }
    },

});


Object.defineProperty(Array.prototype, 'unique', {
    enumerable: false,
    value: function () {
        var a = this.concat()
        for (var i = 0; i < a.length; ++i) {
            for (var j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
        return a;
    }
});


// Array.prototype.unique = function () {
//     var a = this.concat();
//     for (var i = 0; i < a.length; ++i) {x
//         for (var j = i + 1; j < a.length; ++j) {
//             if (a[i] === a[j])
//                 a.splice(j--, 1);
//         }
//     }

//     return a;
// };

/**
 * 
 * @param {String} job_id 
 * @param {String} token 
 */
export const fetchCandidateList = (job_id, token, review_status = null, pagination = null, filter = null, sort = null) => async dispatch => {
    dispatch(onLoadingCandidate());
    try {
        const res = await fetchScores(job_id, token, review_status, pagination, filter, sort);
        const candidateList = [];
        if (res.ok && res.obj) {
            const response = res.obj;
            if (response.error_code === 0) {
                const results = response?.result?.results ? response.result.results : response?.result.length > 0 ? response.result : [];
                const total_candidate_count = response?.result.total_candidate_count ? response?.result.total_candidate_count : response?.result?.length ? response.result.length : 0;
                const total_candidate_filtered_count = response?.result.total_candidate_filtered_count ? response?.result.total_candidate_filtered_count : response?.result?.length ? response.result.length : 0;
                for (const element of results) {
                    const candidate = {
                        name: element.fullname === undefined ? '' : element.fullname,
                        university: element.university === undefined ? '' : element.university,
                        education_history: element.education_history === undefined ? '': element.education_history,
                        // educations: element.education_history === undefined ? '': element.education_history,
                        work_experiences: element.work_experiences === undefined ? '': element.work_experiences,
                        // workExperiences: element.work_experiences === undefined ? '': element.work_experiences,
                        skills: element.skills === undefined ? '': element.skills,
                        languages: element.languages === undefined ? '': element.languages,
                        degree: element.degree === undefined ? '' : element.degree,
                        phoneNum: element.phone_num === undefined ? '' : element.phone_num,
                        countryCode: element.country_code === undefined ? '' : element.country_code,
                        autoScore: element.auto_score === undefined || element.auto_score === -1 ? "-" : element.auto_score,
                        recruiterScore: element.recruiter_score === undefined || element.recruiter_score === -1 ? "-" : element.recruiter_score,
                        imageUrl: element.interview_id,
                        //imageUrl: await gets3object(`${element.interview_id}/profile_${element.interview_id}_qid_1.jpg`, token).then(res => { return res }, reject => { return "" }),
                        //imageUrl: element.pictures ? `${config.baseUrl}/api/recruiter/get_picture/` + element.picture : element.interview_id//await gets3object(`${element.interview_id}/profile_${element.interview_id}_qid_1.jpg`, token).then(res => { return res }, reject => { return "" }),
                        //imageUrl: await gets3object(`${element.interview_id}/profile_${element.interview_id}_qid_1.jpg`, token).then(res => { return res }, reject => { return "" }),
                        email: element.email,
                        questionSet: element.question_set,
                        questionNum: element.question_num ? element.question_num : 0,
                        invitation: element.invitation ? element.invitation : 0,
                        reportSend: element?.report_send ? element?.report_send : 0,
                        reportSent: element?.candidate_pdf_email ? 1 : 0,
                        reminder: element.reminder ? element.reminder : 0,
                        confirmation: element.confirmation ? element.confirmation : 0,
                        complete: element.complete ? element.complete : 0,
                        processing: element.processing ? element.processing : 0,
                        interviewStart: element.interview_start ? element.interview_start : '-',
                        interviewEnd: element.interview_deadline ? element.interview_deadline : '-',
                        videoReviewed: element.video_reviewed,
                        reportReviewed: element.report_reviewed,
                        commentReviewed: element.comments_reviewed,
                        shortlisted: element.shortlisted ? element.shortlisted : 0,
                        outQuotaRecord: element.interview_record,
                        _1stPreference: element?.preference_one,
                        _2ndPreference: element?.preference_two,
                        jobId: element.job_id,
                        reviewStatus: element.review_status,
                        programName: element.program_name,
                        jobName: element.job_name,
                        interviewLanguage: element.interview_language ? element.interview_language : "",
                        interviewId: element.interview_id,
                        unansweredQuestions: element.unanswered_questions,
                        lastInterview: element.last_interview ? element.last_interview : '',
                        firstInterview: element.first_interview ? element.first_interview : '',
                        numOfVideo: element.num_of_videos ? element.num_of_videos : 0,
                        multiQuestions: element.multiQuestions,
                        cv: element.cv ? element.cv : null,
                        cvExtension: element.cvExtension ? element.cvExtension : null,
                        result_shared : element?.result_shared || false,
                        scores: element?.scores || null,
                        total_score: element?.total_score || null,
                    };
                    candidateList.push(candidate);
                }
                dispatch(onLoadingCandidateSuccess({
                    jobId: job_id,
                    candidateList,
                    totalCandidateCount: total_candidate_count,
                    totalCandidateFilteredCount: total_candidate_filtered_count
                }));
                return true;
            } else {
                console.log("Error with error code")
                dispatch(onLoadingCandidateFailed());
                return false;
            }
        } else {
            console.log("Error with api call")
            dispatch(onLoadingCandidateFailed());
        }
        return false;
    } catch (err) {
        console.log(err);
        dispatch(onLoadingCandidateFailed({
            message: err.toString()
        }));
        return false;
    }
};
export const fetchCandidateListByReviewStatus = (job_id, token, pagination = null, filter = null, sort = null) => async dispatch => {
    dispatch(onLoadingCandidate());
    try {
        const res = await fetchCandidatesForManualReviewStatus(token, pagination, filter, sort);
        const candidateList = [];
        if (res.ok) {
            const response = res.obj;
            if (response.error_code === 0) {
                for (const element of response.result) {
                    const candidate = {
                        name: element.fullname === undefined ? '' : element.fullname,
                        university: element.university === undefined ? '' : element.university,
                        education_history: element.education_history === undefined ? '': element.education_history,
                        // educations: element.education_history === undefined ? '': element.education_history,
                        work_experiences: element.work_experiences === undefined ? '': element.work_experiences,
                        // workExperiences: element.work_experiences === undefined ? '': element.work_experiences,
                        skills: element.skills === undefined ? '': element.skills,
                        languages: element.languages === undefined ? '': element.languages,
                        degree: element.degree === undefined ? '' : element.degree,
                        phoneNum: element.phone_num === undefined ? '' : element.phone_num,
                        countryCode: element.country_code === undefined ? '' : element.country_code,
                        autoScore: element.auto_score === undefined || element.auto_score === -1 ? "-" : element.auto_score,
                        recruiterScore: element.recruiter_score === undefined || element.recruiter_score === -1 ? "-" : element.recruiter_score,
                        imageUrl: element.interview_id,
                        //imageUrl: await gets3object(`${element.interview_id}/profile_${element.interview_id}_qid_1.jpg`, token).then(res => { return res }, reject => { return "" }),
                        //imageUrl: element.pictures ? `${config.baseUrl}/api/recruiter/get_picture/` + element.picture : element.interview_id//await gets3object(`${element.interview_id}/profile_${element.interview_id}_qid_1.jpg`, token).then(res => { return res }, reject => { return "" }),
                        //imageUrl: await gets3object(`${element.interview_id}/profile_${element.interview_id}_qid_1.jpg`, token).then(res => { return res }, reject => { return "" }),
                        email: element.email,
                        questionSet: element.question_set,
                        questionNum: element.question_num ? element.question_num : 0,
                        invitation: element.invitation ? element.invitation : 0,
                        reportSend: element?.report_send ? element?.report_send : 0,
                        reportSent: element?.candidate_pdf_email ? 1 : 0,
                        reminder: element.reminder ? element.reminder : 0,
                        confirmation: element.confirmation ? element.confirmation : 0,
                        complete: element.complete ? element.complete : 0,
                        processing: element.processing ? element.processing : 0,
                        interviewStart: element.interview_start ? element.interview_start : '-',
                        interviewEnd: element.interview_deadline ? element.interview_deadline : '-',
                        videoReviewed: element.video_reviewed,
                        reportReviewed: element.report_reviewed,
                        commentReviewed: element.comments_reviewed,
                        shortlisted: element.shortlisted ? element.shortlisted : 0,
                        outQuotaRecord: element.interview_record,
                        _1stPreference: element?.preference_one,
                        _2ndPreference: element?.preference_two,
                        jobId: element.job_id,
                        reviewStatus: element.review_status,
                        programName: element.program_name,
                        jobName: element.job_name,
                        interviewLanguage: element.interview_language,
                        interviewId: element.interview_id,
                        unansweredQuestions: element.unanswered_questions,
                        lastInterview: element.last_interview ? element.last_interview : '',
                        firstInterview: element.first_interview ? element.first_interview : '',
                        timezoneOffset: element.timezone_offset ? element.timezone_offset: '',
                        numOfVideo: element.num_of_videos ? element.num_of_videos : 0,
                        multiQuestions: element.multiQuestions,
                        cv: element.cv ? element.cv : null,
                        cvExtension: element.cvExtension ? element.cvExtension : null,
                        total_score: element.total_score === undefined || element.total_score === -1 ? "-" : element.total_score,
                    };
                    candidateList.push(candidate);
                }

                // try{
                //     const res2 = await fetchAllCandidatesTotalScore(token, job_id);
                //     if (res2.ok){
                //         const response2 = res2.obj;
                //         if (response2.error_code === 0){
                //             response2.message.forEach((element, index) => {
                //                 const candidateListIndex = candidateList.findIndex(ele => ele.email === element.candidate_email)
                //                 if(candidateListIndex !== -1){
                //                     candidateList[candidateListIndex]["scores"] = element.scores
                //                 }
                //             })
                //         }
                //     }}
                // catch{
                    
                // }
                dispatch(onLoadingCandidateSuccess({
                    jobId: job_id,
                    candidateList,
                    totalCandidateCount: response.total_candidates_count,
                    totalCandidateFilteredCount: response.total_filtered_candidates_count
                }));
                return {
                    "status": true,
                    }
            } else {
                console.log("Error with error code")
                dispatch(onLoadingCandidateFailed());
                return {
                    "status": false
                }
            }
        } else {
            console.log("Error with api call")
            dispatch(onLoadingCandidateFailed());
        }
        return {
            "status": false
        }
    } catch (err) {
        console.error(err);
        dispatch(onLoadingCandidateFailed({
            message: err.toString()
        }));
        return {
            "status": false
        }
    }
};

export const fetchCandidateListByGuest = (token, review_status = null) => async dispatch => {
    dispatch(onLoadingCandidate());
    try {
        const res = await fetchScoresByGuest(token, review_status);
        const candidateList = [];
        if (res.ok) {
            const response = res.obj;
            if (response.error_code === 0) {
                for (const element of response.result) {
                    const candidate = {
                        name: element.fullname === undefined ? '' : element.fullname,
                        university: element.university === undefined ? '' : element.university,
                        degree: element.degree === undefined ? '' : element.degree,
                        phoneNum: element.phone_num === undefined ? '' : element.phone_num,
                        countryCode: element.country_code === undefined ? '' : element.country_code,
                        autoScore: element.auto_score === undefined || element.auto_score === -1 ? "-" : element.auto_score,
                        recruiterScore: element.recruiter_score === undefined || element.recruiter_score === -1 ? "-" : element.recruiter_score,
                        imageUrl: element.picture ? `${config.baseUrl}/api/recruiter/get_picture/` + element.picture : '',
                        email: element.email,
                        questionSet: element.question_set,
                        questionNum: element.question_num ? element.question_num : 0,
                        invitation: element.invitation ? element.invitation : 0,
                        reminder: element.reminder ? element.reminder : 0,
                        confirmation: element.confirmation ? element.confirmation : 0,
                        complete: element.complete ? element.complete : 0,
                        processing: element.processing ? element.processing : 0,
                        questionSet: element.question_set,
                        interviewStart: element.interview_start ? element.interview_start : '-',
                        interviewEnd: element.interview_deadline ? element.interview_deadline : '-',
                        videoReviewed: element.video_reviewed,
                        reportReviewed: element.report_reviewed,
                        commentReviewed: element.comments_reviewed,
                        shortlisted: element.shortlisted ? element.shortlisted : 0,
                        outQuotaRecord: element.interview_record,
                        jobId: element.job_id,
                        reviewStatus: element.review_status,
                        programName: element.program_name,
                        jobName: element.job_name,
                        interviewLanguage: element.interview_language,
                    };
                    candidateList.push(candidate);
                }
                dispatch(onLoadingCandidateSuccess({
                    jobId: candidateList[0].jobId,
                    candidateList
                }));
                return true;
            } else {
                console.log("Error with error code")
                dispatch(onLoadingCandidateFailed());
                return false;
            }
        } else {
            console.log("Error with api call")
            dispatch(onLoadingCandidateFailed());
        }
        return false;
    } catch (err) {
        console.log(err);
        dispatch(onLoadingCandidateFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const fetchCandidateProfile = (email, job_id, token, role, interviewId) => async dispatch => {
    dispatch(onLoadingCandidateProfile());
    try {
        const res = await getInfo(email, job_id, token, role, interviewId);
        if (res.ok) {
            const response = res.obj;
            if (response.error_code === 0) {
                let candidateProfile = {};
                candidateProfile.name = response.result.fullname;
                candidateProfile.email = response.result.email;
                candidateProfile.degree = response.result.degree;
                candidateProfile.university = response.result.university;
                candidateProfile._1stPreference = response?.result?.preference_one;
                candidateProfile._2ndPreference = response?.result?.preference_two;
                candidateProfile.questionSet = response.result.question_set;
                candidateProfile.imageUrl = response.result.pictures ? `${config.baseUrl}/api/recruiter/get_picture/${response.result.picture}` : await gets3object(`${response.result.interview_id}/profile_${response.result.interview_id}_qid_1.jpg`, token, BUCKETS.PROCESSED_MEDIA_BUCKET).then(res => { return res }, reject => { return "" })
                candidateProfile.interviewId = response.result.interview_id
                candidateProfile.reviewStatus = response.result.review_status
                candidateProfile.pdfReport_en = response.result.pdf_report_en
                candidateProfile.pdfReport_th = response.result.pdf_report_th
                candidateProfile.extraInfo = response.result.extra_info
                candidateProfile.unansweredQuestions = response.result.unanswered_questions
                candidateProfile.cv = response.result.cv
                candidateProfile.cvExtension = response.result.cvExtension
                candidateProfile.video_interview_model = response.result.video_interview_model || ""
                candidateProfile.ai_required = response.result.ai_required || 0
                candidateProfile.training_report = response.result.training_report || 0
                candidateProfile.manual_review_task = response.result.manual_review_task || {}
                console.log("candidateList.js: video_interview_model", response.result)
                dispatch(onLoadingCandidateProfileSuccess(candidateProfile))
                return res
            } else {
                if (response.error_code === 91){
                    return true
                }
                console.log("Error with error code");
                dispatch(onLoadingCandidateProfileFailed({
                    message: "Error with error code"
                }));
            }
        } else {
            console.log("Error with api call");
            dispatch(onLoadingCandidateProfileFailed({
                message: "Error with error code"
            }));
        }
    } catch (err) {
        console.log(err);
        dispatch(onLoadingCandidateProfileFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const fetchCandidateProfileByGuest = (token) => async dispatch => {
    dispatch(onLoadingCandidateProfile());
    try {
        const res = await getInfoByGuest(token);
        if (res.ok) {
            const response = res.obj;
            if (response.error_code === 0) {
                let candidateProfile = {};
                candidateProfile.name = response.result.fullname;
                candidateProfile.email = response.result.email;
                candidateProfile.degree = response.result.degree;
                candidateProfile.university = response.result.university;
                candidateProfile._1stPreference = response?.result?.preference_one;
                candidateProfile._2ndPreference = response?.result?.preference_two;
                candidateProfile.questionSet = response.result.question_set;
                candidateProfile.imageUrl = response.result.pictures ? `${config.baseUrl}/api/recruiter/get_picture/${response.result.picture}` : await gets3object(`${response.result.interview_id}/profile_${response.result.interview_id}_qid_1.jpg`, token, BUCKETS.PROCESSED_MEDIA_BUCKET).then(res => { return res }, reject => { return "" })
                candidateProfile.interviewId = response.result.interview_id
                candidateProfile.reviewStatus = response.result.review_status
                candidateProfile.pdfReport_en = response.result.pdf_report_en
                candidateProfile.pdfReport_th = response.result.pdf_report_th
                candidateProfile.cv = response.result.cv
                candidateProfile.cvExtension = response.result.cvExtension
                candidateProfile.jobId = response.result.job_id
                candidateProfile.multiQuestions = response?.result?.multiQuestions
                candidateProfile.video_interview_model = response.result.video_interview_model || ""
                candidateProfile.ai_required = response.result.ai_required || 0
                candidateProfile.training_report = response.result.training_report || 0
                dispatch(onLoadingCandidateProfileSuccess(candidateProfile))
            } else {
                console.log("Error with error code");
                dispatch(onLoadingCandidateProfileFailed({
                    message: "Error with error code"
                }));
            }
        } else {
            console.log("Error with api call");
            dispatch(onLoadingCandidateProfileFailed({
                message: "Error with error code"
            }));
        }
    } catch (err) {
        console.log(err);
        dispatch(onLoadingCandidateProfileFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const fetchQuestions = (questionSetNo, candidateEmail, jobId, token, interviewId, labelRequired) => async (dispatch, getState) => {
    dispatch(clearCandidateQuestion())
    dispatch(onFetchQuestions());
    dispatch(clearCandidateQuestion())
    try {
        const res = await fetchSetting(jobId, token);
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const result = res.obj.result;
            const questions = result.multiQuestion ? result.multiQuestion[result.multiQuestion.findIndex(({type}) => type === "Video Interview")].question_sets : result.qtype
            // Load Question URL
            let questionNo = 1;
            let videoPromises = [];
            var flattenQuestions = [];

            var checkEfs = true
            questions.forEach((questionSet, setIndex) => {
                questionSet.questions.forEach((question, index) => {
                    const flattenQuestion = {
                        question: question.question,
                        questionNo,
                        questionSet: setIndex + 1,
                        type: question.type,
                        subType: question.sub_type,
                        url: `${config.baseUrl}/api/reports/get_video/null`,
                        competency: question.competency,
                        lang: question.lang
                    };
                    if (flattenQuestion.questionSet === questionSetNo) {
                        flattenQuestions.push(flattenQuestion);
                        const annotated = labelRequired ? '' : 'wa_'
                        var filename = `${interviewId}/${annotated + interviewId}_qid_${questionNo}_final.mp4`
                        videoPromises.push(gets3object(filename, token, BUCKETS.PROCESSED_MEDIA_BUCKET).then(res => {
                            flattenQuestion.url = res
                            checkEfs = false
                        }, reject => {
                        }));
                        const label = labelRequired ? 'yes' : 'no'
                        videoPromises.push(getVideo(interviewId, label, questionNo, token).then(resp => {
                            let res = resp.body
                            flattenQuestion.city = res.city || "None"
                            flattenQuestion.country = res.country || "None"
                            flattenQuestion.original_text = res.original_text || "None"
                            flattenQuestion.ip = res.ip || "None"
                            flattenQuestion.upload_at = res.upload_at || "None"
                            flattenQuestion.transcribed_speech = res.transcribed_speech || "None"
                            flattenQuestion.competency = question.competency || ["None"]
                            flattenQuestion.translateQuestionText = res.question_translated_text || "No Translated Text Available " + flattenQuestion.questionNo
                        }))
                        questionNo++;
                    }
                });
            });
            await Promise.all(videoPromises);
            if (checkEfs) {
                questionNo = 1;
                videoPromises = [];
                flattenQuestions = [];

                questions.forEach((questionSet, setIndex) => {
                    questionSet.questions.forEach((question, index) => {
                        const flattenQuestion = {
                            question: question.question,
                            questionNo,
                            questionSet: setIndex + 1,
                            type: question.type,
                            subType: question.sub_type,
                            url: `${config.baseUrl}/api/reports/get_video/null`,
                            competency: question.competency,
                            lang: question.lang
                        };
                        if (flattenQuestion.questionSet === questionSetNo) {
                            flattenQuestions.push(flattenQuestion);
                            const label = labelRequired ? 'yes' : 'no';
                            videoPromises.push(getVideo(interviewId, label, questionNo, token).then(resp => {
                                const filename = resp.obj && resp.obj.error_code === 0 ? resp.obj.filename : null;
                                let res = resp.body
                                if (filename) {
                                    flattenQuestion.url = `${config.baseUrl}/api/reports/get_video/${filename}`;
                                    flattenQuestion.city = res.city
                                    flattenQuestion.country = res.country
                                    flattenQuestion.original_text = res.original_text
                                    flattenQuestion.transcribed_speech = res.transcribed_speech
                                    flattenQuestion.translateQuestionText = res.question_translated_text || "No Translated Text Available " + flattenQuestion.questionNo
                                    flattenQuestion.competency = question.competency || ["None"]
                                }
                            }, reject => {
                            }));
                            questionNo++;
                        }
                    });
                });
                await Promise.all(videoPromises);
            }
            dispatch(onFetchQuestionsSuccess(flattenQuestions));
            return questions;
        } else {
            console.log("Error with api call (fetchQuestions)");
            dispatch(onFetchQuestionsFailed());
        }
    } catch (err) {
        console.log(err);
        dispatch(onFetchQuestionsFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const fetchQuestionsWithCustomLabel = (questionSetNo, candidateEmail, jobId, token, interviewId, labelRequired) => async (dispatch, getState) => {
    dispatch(clearCandidateQuestion())
    dispatch(onFetchQuestions());
    dispatch(clearCandidateQuestion())
    try {
        const res = await fetchSetting(jobId, token);
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const result = res.obj.result;
            // console.log("fetchingSetting result: ", result)
            // const questions = result.multiQuestion ? result.multiQuestion[result.multiQuestion.findIndex(({type}) => type === "Video Interview")].question_sets : result.qtype;
            
            
            // const questions = result.multiQuestion ? result.multiQuestion[result.multiQuestion.findIndex(({type}) => type === "Multiple Choices" || type === "Video Interview")].question_sets : result.qtype;
            /**
             * Used to filter and retrieve the video interview questions so that the menu buttons to the left of
             * the Interview Analysis page show the correct tooltip text and the correct number of buttons.
             */
            const questions = result.multiQuestion ? result.multiQuestion[result.multiQuestion.findIndex(({type}) => type === "Video Interview")]?.question_sets : result.qtype;
            console.log("candidateList.js: retrieved questions(video interiview): ", questions)
            // Load Question URL
            let questionNo = 1;
            let videoPromises = [];
            var flattenQuestions = [];
            
            var checkEfs = true
            if (questions && questions.length > 0){
                questions.forEach((questionSet, setIndex) => {
                    questionSet.questions.forEach((question, index) => {
                        const flattenQuestion = {
                            question: question.question,
                            questionNo,
                            questionSet: setIndex + 1,
                            type: question.type,
                            subType: question.sub_type,
                            url: `${config.baseUrl}/api/reports/get_video/null`
                        };
                        if (flattenQuestion.questionSet === questionSetNo) {
                            flattenQuestions.push(flattenQuestion);
                            const annotated = labelRequired ? '' : 'wa_'
                            var filename = `${interviewId}/${annotated + interviewId}_qid_${questionNo}_final.mp4`
                            videoPromises.push(gets3object(filename, token, BUCKETS.PROCESSED_MEDIA_BUCKET).then(res => {
                                flattenQuestion.url = res
                                checkEfs = false
                            }, reject => {
                            }));
                            const label = labelRequired ? 'yes' : 'no'
                            videoPromises.push(getVideo(interviewId, label, questionNo, token).then(resp => {
                                let res = resp.body
                                flattenQuestion.city = res.city || "None"
                                flattenQuestion.country = res.country || "None"
                                flattenQuestion.original_text = res.original_text || "None"
                                flattenQuestion.ip = res.ip || "None"
                                flattenQuestion.upload_at = res.upload_at || "None"
                                flattenQuestion.transcribed_speech = res.transcribed_speech || "None"
                                flattenQuestion.competency = question.competency || ["None"]
                            }))
                            questionNo++;
                        }
                    });
                });
            }
            await Promise.all(videoPromises);
            if (checkEfs && questions) {
                questionNo = 1;
                videoPromises = [];
                flattenQuestions = [];

                questions.forEach((questionSet, setIndex) => {
                    questionSet.questions.forEach((question, index) => {
                        const flattenQuestion = {
                            question: question.question,
                            questionNo,
                            questionSet: setIndex + 1,
                            type: question.type,
                            subType: question.sub_type,
                            url: `${config.baseUrl}/api/reports/get_video/null`
                        };
                        if (flattenQuestion.questionSet === questionSetNo) {
                            flattenQuestions.push(flattenQuestion);
                            const label = labelRequired ? 'yes' : 'no';
                            videoPromises.push(getVideo(interviewId, label, questionNo, token).then(resp => {
                                const filename = resp.obj && resp.obj.error_code === 0 ? resp.obj.filename : null;
                                let res = resp.body
                                if (filename) {
                                    flattenQuestion.url = `${config.baseUrl}/api/reports/get_video/${filename}`;
                                    flattenQuestion.city = res.city
                                    flattenQuestion.country = res.country
                                    flattenQuestion.original_text = res.original_text
                                    flattenQuestion.transcribed_speech = res.transcribed_speech
                                    flattenQuestion.competency = question.competency || ["None"]
                                }
                            }, reject => {
                            }));
                            questionNo++;
                        }
                    });
                });
                await Promise.all(videoPromises);
            }
            dispatch(onFetchQuestionsSuccess(flattenQuestions));
            return questions;
        } else {
            console.log("Error with api call (fetchQuestions)");
            dispatch(onFetchQuestionsFailed());
        }
    } catch (err) {
        console.log(err);
        dispatch(onFetchQuestionsFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const fetchQuestionsWithCustomLabelByGuest = (questionSetNo, token, interviewId, labelRequired) => async (dispatch, getState) => {
    dispatch(onFetchQuestions());
    dispatch(clearCandidateQuestion())
    try {
        const res = await fetchSettingByGuest(token);
        let job_id_guest = res.obj.result.job_id
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const result = res.obj.result;
            const questions = result.multiQuestion ? result.multiQuestion[result.multiQuestion.findIndex(({type}) => type === "Video Interview")]?.question_sets : result.qtype;
            // Load Question URL
            let questionNo = 1;
            let videoPromises = [];
            var flattenQuestions = [];
            var checkEfs = true
            questions.forEach((questionSet, setIndex) => {
                questionSet.questions.forEach((question, index) => {
                    const flattenQuestion = {
                        question: question.question,
                        questionNo,
                        questionSet: setIndex + 1,
                        type: question.type,
                        subType: question.sub_type,
                        url: `${config.baseUrl}/api/reports/get_video/null`,
                        competency: question.competency,
                        lang: question.lang
                    };
                    if (flattenQuestion.questionSet === questionSetNo) {
                        flattenQuestions.push(flattenQuestion);
                        const annotated = labelRequired ? '' : 'wa_'
                        var filename = `${interviewId}/${annotated + interviewId}_qid_${questionNo}_final.mp4`
                        videoPromises.push(gets3object(filename, token, BUCKETS.PROCESSED_MEDIA_BUCKET).then(res => {
                            flattenQuestion.url = res
                            checkEfs = false
                        }, reject => {
                        }));
                        questionNo++;
                    }
                });
            });
            await Promise.all(videoPromises);

            if (checkEfs) {
                questionNo = 1;
                videoPromises = [];
                flattenQuestions = [];
                questions.forEach((questionSet, setIndex) => {
                    questionSet.questions.forEach((question, index) => {
                        var flattenQuestion = {
                            question: question.question,
                            questionNo,
                            questionSet: setIndex + 1,
                            type: question.type,
                            subType: question.sub_type,
                        };
                        if (flattenQuestion.questionSet === questionSetNo) {
                            flattenQuestions.push(flattenQuestion);
                            const label = labelRequired ? 'yes' : 'no'
                            console.log(label)
                            videoPromises.push(getVideoByGuest(token, label, questionNo).then(resp => {
                                const filename = resp.obj && resp.obj.error_code === 0 ? resp.obj.filename : null;
                                flattenQuestion.url = `${config.baseUrl}/api/reports/get_video/${filename}`;
                            }));
                            questionNo++;
                        }
                    });
                });
                await Promise.all(videoPromises);
            }

            dispatch(onFetchQuestionsSuccess(flattenQuestions));
            return questions;
        } else {
            console.log("Error with api call (fetchQuestions)");
            dispatch(onFetchQuestionsFailed());
        }
    } catch (err) {
        console.log(err);
        dispatch(onFetchQuestionsFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const fetchMultiQuestion = (jobId, interviewId, token) => async dispatch => {
    dispatch(clearCandidateMultiQuestion())
    dispatch(onFetchQuestions());
    dispatch(clearCandidateMultiQuestion())
    try {
        const res = await fetchSetting(jobId, token);
        var flattenMultiQuestions = []
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const result = res.obj.result
            const multiQuestion = result.multiQuestion
            if(multiQuestion){
                if (multiQuestion.some(e => e.type === "Short Question")){
                    const res2 = await fetchShortQuestionAnswerApi (jobId, interviewId, token)
                    if (res2.ok && res2.obj && res2.obj.error_code === 0) {
                        flattenMultiQuestions = flattenMultiQuestions.concat(res2.obj.message)
                    }
                }

                if (multiQuestion.some(e => e.type === "Multiple Choices")){
                    const res2 = await checkMultipleChoiceAnswersApi (jobId, interviewId, token)
                    if (res2.ok && res2.obj && res2.obj.error_code === 0) {
                        flattenMultiQuestions = flattenMultiQuestions.concat(res2.obj.message)
                    }
                }
                dispatch(onFetchMultiQuestionSuccess({
                    flattenQuestions: flattenMultiQuestions,
                    testType: res.obj.result.testtype
                }))
            }else{
                dispatch(onFetchMultiQuestionSuccess({
                    flattenQuestions: flattenMultiQuestions,
                    testType: ["Video Interview"]
                }))
            }
        }else{
            console.log("Error with api call (fetchMultiQuestions)");
            dispatch(onFetchQuestionsFailed());
        }
    }catch(err) {
        console.log(err);
        dispatch(onFetchQuestionsFailed({
            message: err.toString()
        }));
    }
}

export const fetchMultiQuestionByGuest = (jobId, interviewId, guestToken) => async dispatch => {
    dispatch(clearCandidateMultiQuestion())
    dispatch(onFetchQuestions());
    dispatch(clearCandidateMultiQuestion())
    try {
        const res = await fetchSettingByGuest(guestToken);
        var flattenMultiQuestions = []
        if (res.ok && res.obj && res.obj.error_code === 0){
            const result = res.obj.result
            const multiQuestion = result.multiQuestion
            if(multiQuestion){
                if (multiQuestion.some(e => e.type === "Short Question")){
                    const res2 = await fetchShortQuestionAnswerApi (jobId, interviewId, guestToken)
                    if (res2.ok && res2.obj && res2.obj.error_code === 0) {
                        flattenMultiQuestions = flattenMultiQuestions.concat(res2.obj.message)
                    }
                }

                if (multiQuestion.some(e => e.type === "Multiple Choices")){
                    const res2 = await checkMultipleChoiceAnswersApi (jobId, interviewId, guestToken)
                    if (res2.ok && res2.obj && res2.obj.error_code === 0) {
                        flattenMultiQuestions = flattenMultiQuestions.concat(res2.obj.message)
                    }
                }

                dispatch(onFetchMultiQuestionSuccess({
                    flattenQuestions: flattenMultiQuestions,
                    testType: res.obj.result.testtype
                }))
            }else{
                dispatch(onFetchMultiQuestionSuccess({
                    flattenQuestions: flattenMultiQuestions,
                    testType: ["Video Interview"]
                }))
            }
        } else {
            console.log("Error with api call (fetchMultiQuestions)");
            dispatch(onFetchQuestionsFailed());
        }
    } catch (err) {
        console.log(err);
        dispatch(onFetchQuestionsFailed({
            message: err.toString()
        }));
        return false;
    }
}

/**
 * 
 * @param {String} job_id 
 * @param {[String]} email 
 * @param {String} token 
 */
export const deleteCandidate = (job_id, interview_ids, token) => async dispatch => {
    dispatch(onDeletingCandidate());
    try {
        const res = await delCandidate(job_id, interview_ids, token);
        if (res.ok) {
            if (res.obj.error_code === 0) {
                dispatch(onDeletingCandidateSuccess(interview_ids));
                return true
            } else {
                console.log("Error with error code")
                dispatch(onDeletingCandidateFailed());
                return false
            }
        } else {
            console.log("Error with api call")
            dispatch(onDeletingCandidateFailed());
            return false
        }
    } catch (err) {
        console.log(err);
        dispatch(onDeletingCandidateFailed({
            message: err.toString()
        }));
        return false;
    }
}

/**
 * 
 * @param {File} candidate_file 
 * @param {String} token 
 * @param {String} job_id 
 */
export const importCandidate = (candidate_file, token, job_id, startDate, endDate, type) => async dispatch => {
    dispatch(onImportingCandidate());
    try {
        console.log('Start Import')
        var res;
        if (type === 0) {
            res = await importCandidateByJson(candidate_file, token, job_id, startDate, endDate);
        } else if (type === 1) {
            res = await importCan(candidate_file, token, job_id, startDate, endDate);
        } else if (type === 2){
            res = await importCandidateByCV(candidate_file, token, job_id, startDate, endDate)
        }
        console.log(res)
        console.log('Finish Import')
        if (res.ok) {
            const response = res.obj;
            if (response.error_code === 0) {
                dispatch(onImportingCandidateSuccess());
            } else {
                dispatch(onImportingCandidateFailed(response));
            }
            return response
        } else {
            console.log("Error with api call (importCandidate)")
            dispatch(onImportingCandidateFailed());
            return {
                'error_code': 4
            }
        }
    } catch (err) {
        console.log(err);
        dispatch(onImportingCandidateFailed({
            message: err.toString()
        }));
        return {
            'error_code': 5
        };
    }
}

export const importUsingAccessCode = (accessToken, name, job_id) => async dispatch => {
    dispatch(onImportingCandidate());
    try{
        console.log('Start Import')
        let res;
        res = await importCandidateByAccessCode(accessToken, name, job_id)
        console.log(res)
        console.log('Finish Import')
        if(res.ok){
            const response = res.obj
            if(response.error_code === 0){
                dispatch(onImportingCandidateSuccess());
            } else {
                dispatch(onImportingCandidateFailed(response));
            }
            return response
        } else {
            console.log("Error with api call (importCandidate)")
            dispatch(onImportingCandidateFailed());
            return {
                'error_code': 4
            }
        }
    } catch (err){
        console.log(err)
        dispatch(onImportingCandidateFailed({
            message: err.toString()
        }));
        return {
            'error_code': 5
        }
    }
}
/**
 * 
 * @param {[String]} candidate_interview_id 
 * @param {[String]} export_key 
 * @param {String} job_id 
 * @param {String} token 
 */
export const exportCandidate = (candidate_interview_id, job_id, token) => async dispatch => {
    dispatch(onExportingCandidate());
    try {
        // TODO update the estimated number of candidates limit
        if (candidate_interview_id.length > 10000) {
            dispatch(onExportingCandidateFailed({
                message: "Candidates limit exceeded"
            }));
            return {isSuccess: false, errorCode: 1, message: "Candidates limit exceeded"};
        }
        console.log('candidate_interview_id', candidate_interview_id);
        const filename = `Candidates_${job_id}_${new Date().valueOf()}.xlsx`;
        const res = await exportData(candidate_interview_id, ['personal_info', 'interview_info', 'interview_result', 'candidate_answer'], [job_id], token, false, filename);
        console.log('exportCandidate res', res);
        if (res.ok && res.obj.error_code === 0) {
            dispatch(onExportingCandidateSuccess());
            return {isSuccess: true, errorCode: 0, message: "Exporting candidates successfully"};
        } else {
            console.log("Error with api call")
            dispatch(onExportingCandidateFailed({
                message: "Something went wrong while exporting candidates"
            }));
            return {isSuccess: false, errorCode: res?.obj?.error_code, message: res?.obj?.message};

        }
    } catch (err) {
        dispatch(onExportingCandidateFailed({
            message: "Something went wrong while exporting candidates"
        }));
        return {isSuccess: false, errorCode: 9, message: "Something went wrong while exporting candidates"};
    }
}

export const removeViewingCandidate = () => async dispatch => {
    dispatch(onDeletingViewingCandidate());
}

export const exportPercentageCandidate = (percentage, export_key, job_ids, token, isSelectJobs) => async dispatch => {
    dispatch(onExportingCandidate());
    try {
        var candidateList = [];
        var interviewIdList = candidateList.map(candidate => candidate.interview_id).unique();
        console.log("emailList", interviewIdList);
        let filename = `Candidates_${new Date().valueOf()}.xlsx`
        if (job_ids.length > 1) {
            filename = `Multi_Jobs_${new Date().valueOf()}.xlsx`
        } else if (job_ids.length === 1) {
            filename = `${job_ids[0]}_${new Date().valueOf()}.xlsx`
        }
        const res = await exportData(interviewIdList, export_key, job_ids, token, isSelectJobs, filename);
        console.log("exportCandidate res", res)
        if (res.ok && res.obj.error_code === 0) {

            dispatch(onExportingCandidateSuccess());
            return true;
        } else {
            console.log("Error with api call")

        }
    } catch (err) {
        console.log(err);
        dispatch(onExportingCandidateFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const submitComment = (jobId, email, scoring, totalScore, recruiter_token, interviewId) => async dispatch => {
    dispatch(onSubmitComment());
    try {
        const res = await submitComments(jobId, email, scoring, totalScore, recruiter_token, interviewId);
        if (res.ok) {
            if(res.obj && res.obj.error_code === 0){
                dispatch(onSubmitCommentSuccess(scoring));
                return {
                    "check": true,
                    "msg": res.obj,
                };
            } else {
                dispatch(onSubmitCommentFailed());
                return {
                    "check": true,
                    "msg": res.obj
                }
            }
        } else {
            console.log("Error with api call")
            dispatch(onSubmitCommentFailed());
            return {
                "check": false
            };
        }
    } catch (err) {
        console.log(err);
        dispatch(onSubmitCommentFailed({
            message: err.toString()
        }));
        return {
            "check": false,
        };
    }
};

export const submitCommentByGuest = (token, scoring) => async dispatch => {
    dispatch(onSubmitComment());
    try {
        const res = await submitCommentsByGuest(token, scoring);
        if (res.ok) {
            dispatch(onSubmitCommentSuccess(scoring));
            return true;
        } else {
            console.log("Error with api call")
            dispatch(onSubmitCommentFailed());
            return false;
        }
    } catch (err) {
        console.log(err);
        dispatch(onSubmitCommentFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const retrieveComment = (token, jobId, email, interviewId) => async dispatch => {
    dispatch(onRetrieveComment());
    try {
        const res = await retrieveComments(jobId, email, token, interviewId);
        const setting = await fetchSetting(jobId, token);
        if (res.ok && setting.ok) {
            dispatch(onRetrieveCommentSuccess({
                scoring: res.obj,
                weighting: setting.obj.result.weighting,
            }));
            return true;
        } else {
            console.log("Error with api call");
            dispatch(onRetrieveCommentFailed());
            return false;
        }
    } catch (err) {
        console.log(err);
        dispatch(onRetrieveCommentFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const fetchReviewerEvaluation = (token, jobId, email, interviewId) => async dispatch => {
    dispatch(onFetchReviewerEvaluation())
    let ret = false

    const wholeCategory = [
        'attrativeness',
        'enthusiasm',
        'self_confidence',
        'composure',
        'communication',
        'openness',
        'conscientiousness',
        'extraversion',
        'agreeableness',
        'neuroticism',
        'pleasantness',
        'patience',
        'composure_s4m',
        'independence',
        'learning_agility',
        'adaptability_and_supporting',
        'caring_and_sensitivity',
        'responsible_with_ownership',
        'interpersonal_relationship',
        'english_oral_communication_ongrad',
        'responsibility_and_goal_achievement_ongrad',
        'managing_execution_ongrad',
        'analytical_and_logical_thinking_ongrad',
        'complex_problem_solving_ongrad',
        'teamwork_and_collaboration_ongrad',
        'building_relationship_and_people_ongrad',
        'caring_and_empathy_ongrad',
        'communication_with_impact_ongrad',
        'flexibility_and_active_learning_ongrad',
        'creativity_and_innovation_ongrad',
        'motivation_ongrad',
        'motivation_ongrad_b',
        'emotional_intelligence_ongrad',
        'enthusiasm_ongrad',
        'confidence_ongrad',
        'composure_ongrad',
    ]
    try {
        const res = await getReviewerScoring(token, jobId, email, interviewId)
        if (res.ok && res.obj.error_code === 0) {
            //const result = {}
            //for (var category of wholeCategory) {
            //    console.log(res.obj)
            //    result[category] = res.obj.result.reviewer_score && res.obj.result.reviewer_score[category] ? res.obj.result.reviewer_score[category] : res.obj.result.scoring_metrics[category] ? Math.round(res.obj.result.scoring_metrics[category]) : undefined
            //}
            if (!res.obj.result?.reviewer_score){
                dispatch(onFetchReviewerEvaluationSuccess(false))
                return ret
            }
            const result = assign({},
                assign({}, ...map(wholeCategory, category => ({ [category]: undefined }))),
                //get( res, `obj.result.scoring_metrics`, {} ),
                get(res, `obj.result.reviewer_score`, {}),
            )
            
            dispatch(onFetchReviewerEvaluationSuccess(result))
            ret = true
        } else {
            dispatch(onFetchReviewerEvaluationFailed())
            ret = false
        }
    }
    catch (err) {
        console.error(err)
        dispatch(onFetchReviewerEvaluationFailed({
            message: err.toString()
        }))
        ret = false
    }
    return ret
}

// export const submitReviewerEvaluation = (token, jobId, email, scoring, interviewId) => async dispatch => {
//     dispatch(onSubmitReviewerEvaluation());
//     try {
//         const res = await submitReviewerScoring(token, jobId, email, scoring, interviewId);
//         if (res.ok && res.obj.error_code === 0) {
//             dispatch(onSubmitReviewerEvaluationSuccess(scoring));
//             return res.obj;
//         } else {
//             console.log("Error with api call")
//             dispatch(onSubmitReviewerEvaluationFailed());
//             return false;
//         }
//     } catch (err) {
//         console.log(err);
//         dispatch(onSubmitReviewerEvaluationFailed({
//             message: err.toString()
//         }));
//         return false;
//     }
// };

const downloadFromUrl = (url, name) => {
    fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name;
      link.click();
  }).catch(e => {
    console.log(e)
  })
}

export const getPdfReport = (token, jobId, interviewId, pdfReport, name, lang) => async dispatch => {
    let msg = null
    dispatch(onGettingPdfReport())
    // const url = `/api/reports/pdf_report/${pdfReport}`

    // // check existence of pdf report
    // let exist = false
    // try {
    //     exist = !isNil(pdfReport) && get(await axios.head(url), 'status') === 200
    // } catch { }
    // if (exist) {
    //     // download pdf report
    //     downloadFromUrl(url, name)
    // } else {
        try {
            // request pdf report
            const res = await getPdfReportApi(token, jobId, interviewId, lang)
            if (get(res, 'ok') === true && get(res, 'obj.error_code') === 0) {
                // download pdf report
                downloadFromUrl(`${get(res, 'obj.report')}`, name)
            } else {
                msg = res.obj
                console.log(msg)
            }
        } catch (e) {
            console.error("An error occured while fetching pdf report")
            console.log(e)
            msg = `${e}`
        }
    // }
    if (isNil(msg)) {
        dispatch(onGettingPdfReportSuccess())
    } else {
        dispatch(onGettingPdfReportFailed({
            message: msg
        }))
    }
    return msg
}

export const getPdfTrainingReport = (token, jobId, interviewId, pdfReport, name, lang) => async dispatch => {
    let msg = null
    console.log('candidateList.js: getPdfTrainingReport')
    dispatch(onGettingPdfTrainingReport())
        try {
            // request pdf report
            const res = await getPdfTrainingReportApi(token, jobId, interviewId, lang)
            if (get(res, 'ok') === true && get(res, 'obj.error_code') === 0) {
                // download pdf report
                downloadFromUrl(`${get(res, 'obj.report')}`, name)
            } else {
                msg = res.obj
                console.log(msg)
            }
        } catch (e) {
            console.error("An error occured while fetching pdf training report")
            console.log(e)
            msg = `${e}`
        }
    if (isNil(msg)) {
        dispatch(onGettingPdfTrainingReportSuccess())
    } else {
        dispatch(onGettingPdfTrainingReportFailed({
            message: msg
        }))
    }
    return msg
}

export const getEmotions = (token, jobId, interviewId, questionId) => async dispatch => {
    let ret = false
    dispatch(onGettingEmotions())
    try {
        const res = await getEmotionsApi(token, jobId, interviewId, questionId)
        if (get(res, 'ok') === true && get(res, 'obj.error_code') === 0) {
            const result = get(res, 'obj.result', {})
            dispatch(onGettingEmotionsSuccess(result))
            ret = true
        } else {
            console.log('Error with api call')
            dispatch(onGettingEmotionsFailed({
                message: 'Error with api call',
            }))
        }
    } catch (e) {
        console.log(e)
        dispatch(onGettingEmotionsFailed({
            message: e.toString()
        }))
    } finally {
        return ret
    }
}

export const listS3Files = (token, interviewId) => async dispatch => {
    let ret = false
    dispatch(onListingS3Files())
    try {
        const res = await listS3FilesApi(token, interviewId)
        if (get(res, 'obj.error_code') === 0) {
            dispatch(onListingS3FilesSuccess({
                files: get(res, 'obj.files', []),
                s3files: get(res, 'obj.s3files', []),
            }))
            ret = true
        } else {
            console.log('Error with api call')
            dispatch(onListingS3FilesFailed({
                message: 'Error with api call',
            }))
        }
    } catch (e) {
        console.log(e)
        dispatch(onListingS3FilesFailed({
            message: e.toString(),
        }))
    } finally {
        return ret
    }
}

export const transferS3Files = (token, interviewId, filenames) => async dispatch => {
    let ret = false
    dispatch(onTransferringS3Files())
    try {
        let transferredCount = 0
        let failedFiles = []
        for (const filename of filenames) {
            const res = await transferS3FileApi(token, interviewId, filename)
            if (get(res, 'obj.error_code') === 0) {
                transferredCount += 1
            } else {
                failedFiles.push(filename)
            }
            dispatch(onTransferringS3FilesProgress({
                transferS3FilesCount: transferredCount,
                transferS3FilesFailures: failedFiles,
            }))
        }
        if (transferredCount > 0) {
            dispatch(onTransferringS3FilesSuccess({
                transferS3FilesCount: size(filenames),
                transferS3FilesFailures: failedFiles,
            }))
        } else {
            dispatch(onTransferringS3FilesFailed({
                message: 'Error with api call',
            }))
        }
        ret = transferredCount > 0
    } catch (e) {
        console.log(e)
        dispatch(onTransferringS3FilesFailed({
            message: e.toString(),
        }))
    } finally {
        return ret
    }
}

export const updateShortQuestionResult = (interviewId, questionNum, questionTask, result, totalScore, token) => async dispatch => {
    dispatch(onUpdatingShortQuestionResult({questionNum, questionTask, result, totalScore}))
    let ret = false
    try {
        const res = await checkShortQuestionAnswersApi(interviewId, questionNum, questionTask, result, totalScore, token)
        if (get(res, 'obj.error_code') === 0) {
            ret = true
        } else {
            console.log('Error with api call')
        }
    } catch (e) {
        console.log(e)
    } finally {
        return ret
    }
}

export const {
    onLoadingCandidate,
    onLoadingCandidateSuccess,
    onLoadingCandidateFailed,
    onLoadingCandidateProfile,
    onLoadingCandidateProfileSuccess,
    onLoadingCandidateProfileFailed,
    onDeletingCandidate,
    onDeletingViewingCandidate,
    onDeletingCandidateSuccess,
    onDeletingCandidateFailed,
    onImportingCandidate,
    onImportingCandidateSuccess,
    onImportingCandidateFailed,
    onExportingCandidate,
    onExportingCandidateSuccess,
    onExportingCandidateFailed,
    search,
    reviewer_search,
    setCurrentViewingCandidate,
    onFetchQuestions,
    onFetchQuestionsSuccess,
    onFetchQuestionsFailed,
    onSubmitComment,
    onSubmitCommentSuccess,
    onSubmitCommentFailed,
    onRetrieveComment,
    onRetrieveCommentSuccess,
    onRetrieveCommentFailed,
    clearProfile,
    updateImportProgress,
    clearImportRepeatedEmail,
    onFetchReviewerEvaluation,
    onFetchReviewerEvaluationSuccess,
    onFetchReviewerEvaluationFailed,
    // onSubmitReviewerEvaluation,
    // onSubmitReviewerEvaluationSuccess,
    // onSubmitReviewerEvaluationFailed,
    onGettingPdfReport,
    onGettingPdfReportSuccess,
    onGettingPdfReportFailed,
    onGettingPdfTrainingReport,
    onGettingPdfTrainingReportSuccess,
    onGettingPdfTrainingReportFailed,
    onGettingEmotions,
    onGettingEmotionsSuccess,
    onGettingEmotionsFailed,
    reviewer_sort,
    onListingS3Files,
    onListingS3FilesSuccess,
    onListingS3FilesFailed,
    onTransferringS3Files,
    onTransferringS3FilesProgress,
    onTransferringS3FilesSuccess,
    onTransferringS3FilesFailed,
    clearCandidateQuestion,
    onFetchMultiQuestionSuccess,
    clearCandidateMultiQuestion,
    onUpdatingShortQuestionResult,
    setPagination,
    toggleSelectAllCandidates,
    toggleSelectedCandidate,
    setSelectCandidates,
} = candidateListSlice.actions;

export default candidateListSlice.reducer;
