import axios, { post, put } from 'axios'
import axiosRetry from 'axios-retry';
import {
  createSlice
} from 'redux-starter-kit'
import {
  assignQuestion,
  fetchStatus,
  fetchSetting,
  fetchSettingInDangerousEnvironment,
  getInfos,
  getQuestion,
  getInterviewStarted,
  postInterviewVideoStatus,
  fetchVideoStatus,
  getCompanyName,
  recordRedirectStatusApi,
  markRetryApi,
  getS3VideoStatusesApi,
  updateTestStatusApi,
  assignTestDeadlineApi,
  updateCurrentQuestionApi,
  getConsentCandidate,
  checkCandidateConsentStatus,
  createConsentStatus,
  updateVideoInterviewRetryIndex,
  resetDefaultTriggeredStatusApiCall,
  postupdateNumberOfAttempts,
  updateUnansweredQuestions,
  fetchVoiceActivationDetectionStatus,
  recordQuotaUsageApi,
  fetchVirtualAvatars,
} from "../apis/jobseeker";
import {
  posts3Object,
  create_upload,
  multi_upload,
  complete_upload,
  gets3object,
  gets3objectWithJobId,
  create_s3_upload_url_simple,
  checkS3ObjectExists,
  triggerAudioProcessingLambda,
} from '../apis/s3access'
import {
  triggerTextToSpeech,
  triggerTextToSpeechAll
} from '../apis/voice'
import {
  uploadTest,
  uploadPractice,
  uploadVideo,
  getTask,
  check,
  sendLog
} from '../apis/tasks';
import {
    fetchMultiquestionAnswersApi,
    submitMultiquestionAnswersApi,
} from '../apis/reports';
import sha1 from "js-sha1";
import _ from 'lodash';
import {
  mapLocale
} from '../utils/programmeUtils'
import config from '../config'
import {
  BUCKETS,
  interviewLayout
} from '../constants/constants'

const cookiePathMapping = {
  "currentVideoInterviewRetryCount": {
    name: "currentVideoInterviewRetryCount"
  },
  "defaultTriggered": {
    name: "defaultTriggered",
  }
}

let initialState = {
  stage: "setup", //setup, rehearsal, interview, finish
  deadline: null,
  companyLogo: null,
  interviewId: null,
  avatar: true,
  showAvatarIdentity: true,
  useVirtualAvatar: false,
  virtualAvatar: null,
  virtualAvatars: [],
  colorScheme: null,
  fullname: '',
  jobId: null,
  interviewJobTitle: null,
  interviewCompany: 'Company Name Missing...',
  interviewLanguage: null,
  interviewLanguageVariant: null,
  interviewLanguageList: [],
  hasVideoIntroduction: false,
  interviewHomeScreen: null,
  emailVerification: false,
  totalQuestion: 0,
  timeLimit: 0,
  timeLimitPerQuestion: 2,
  currentQuestion: 0,
  realQuestions: [],
  practiceQuestions: [],
  getProfileInProgress: false,
  /**
   * Update: 19 April 2024
   * TODO: Remove deprecated functionality
   */
  videoSubmissionStatuses: [],
  videoSubmissionError: null,
  sampleRate: 1280000,
  questionDetail: null,
  colorBlindTest: false,
  practiceAudio: null,
  displayLayout: interviewLayout.SIDE_BY_SIDE,
  preparationTimeLimit: 2,
  interviewerFirstname: '',
  interviewerLastname: '',
  interviewerPosition: '',
  interviewerAvatar: '/images/mock-interview-4.jpg',
  interviewerCompany: '',
  companyWebsite: '',
  unansweredQuestions: [],
  retake: 0,
  retry: 0,
  s3VideoStatuses: null,
  finishPractice: false,
  uploadProgress: [],
  uploadCompleted: [],
  email:"",
  old:"",
  consentGiven: false,
  consentApiMessage: "",
  infoUploaded: 0,
  candidate_upload_cv_required: false,
  quotaCounted: 0,
  currentVideoInterviewRetryCount: 0,
  defaultTriggered: 0,
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const practiceQuestions = {
  "en": [
    "What are your hobbies and interests?",
    "What's your favourite sport?",
    "Who do you admire most?"
  ],
  "tc": [
    "你的愛好和興趣是什麼？",
    "你最喜歡的運動是什麼？",
    "你最仰慕的人是誰？",
  ],
  "sc": [
    "你的爱好和兴趣是什么？",
    "你最喜欢的运动是什么？",
    "你最仰慕的人是谁？",
  ],
  'th': [
    'งานอดิเรกและความสนใจของคุณคืออะไร?',
    'กีฬาโปรดของคุณคืออะไร?',
    'คุณชอบใครมากที่สุด?',
  ],
  'ja': [
    'あなたの趣味や興味のあるものは何ですか?',
    'あなたが好きなスポーツは?',
    '誰を一番尊敬していますか?',
  ],
  'id': [
    'Apa hobi dan minat Anda?',
    'Apa olahraga favoritmu?',
    'Siapa yang paling kamu kagumi?',
  ],
  'ms': [
    'Apakah hobi dan minat anda?',
    'Apakah sukan kegemaran anda?',
    'Siapa yang paling anda kagumi?',
  ],
  'vi': [
    'Sở thích của bạn là gì?',
    'Môn thể thao yêu thích của bạn là gì?',
    'Bạn ngưỡng mộ ai nhất?',
  ],
  'km': [
    'តើអ្វីជាចំណូលចិត្ត និងចំណាប់អារម្មណ៍របស់អ្នក។?',
    'តើកីឡាអ្វីដែលអ្នកចូលចិត្តជាងគេ?',
    'តើអ្នកកោតសរសើរអ្នកណាជាងគេ?',
  ],
  'de': [
    'Was sind Ihre Hobbys und Interessen?',
    'Was ist dein Lieblingssport?',
    'Wen bewundern Sie am meisten?'
  ],
  'fr': [
    'Quels sont vos hobbies et intérêts?',
    'Quel est votre sport préféré?',
    'Qui admirez-vous le plus?'
  ],
  'kr': [
    '취미와 관심사는 무엇입니까?',
    '가장 좋아하는 스포츠가 무엇입니까?',
    '당신은 누구를 가장 존경합니까?'
  ],
  "en_color": [
    "What are the numbers in this image? Also, please show the answer in sequence (Top, Left, Right)",
    "What are the numbers in this image? Also, please show the answer in sequence (Top, Left, Right) ",
  ],
  "ta": [
    "Anu-ano ang iyong mga hilig at interes?",
    "Ano ang paborito mong isport?",
    "Sino ang pinaka hinahangaan mo?"
  ],
  "es": [
    "¿Cuáles son tus pasatiempos e intereses?",
    "¿Cuál es tu deporte favorito?",
    "¿A quién admiras más?"
  ],
  "pt": [
    "Quais são seus hobbies e interesses?",
    "Qual é o seu esporte favorito?",
    "Quem você mais admira?"
  ],
  "hi": [
    "आपके शौक और रुचियां क्या हैं?",
    "आपका पसंदीदा खेल कौन सा है?",
    "आप सबसे ज्यादा किसकी प्रशंसा करते हैं?"
  ],
  "it": [
    "Quali sono i tuoi hobby e interessi?",
    "Qual è il tuo sport preferito?",
    "Chi ammiri di più?"
  ],
  "ar": [
    "ما هي هواياتك واهتماماتك؟",
    "ما هو رياضتك المفضلة؟",
    "من الذي تعجب به أكثر؟"
  ],
  "pl": [
    "Jakie są twoje hobby i zainteresowania?",
    "Jaki jest twój ulubiony sport?",
    "Kogo najbardziej podziwiasz?"
  ],
  "tr": [
    "Hobileriniz ve ilgi alanlarınız nelerdir?",
    "En sevdiğiniz spor nedir?",
    "En çok kimi hayranlıkla izliyorsunuz?"
  ]
};

const practiceMCQs = [
  {
      ans_type: "Single Answer",
      choices: [
          {
            en: "true", 
            ms: "benar",
            km: "ពិត",
            vi: "thật",
            id: "BENAR",
            ja: "真実",
            th: "จริง",
            sc: "真的",
            tc: "真的",
            de: "stimmt", 
            fr: "vraie", 
            kr: "진실", 
            ta: "totoo",
            es: "verdadero",
            pt: "verdadeiro",
            hi: "सत्य",
            it: "vero",
            ar: "صحيح",
            pl: "prawda",
            tr: "doğru"
          }, {
            en: "false", 
            ms: "salah",
            km: "មិនពិត",
            vi: "sai",
            id: "Salah",
            ja: "間違い",
            th: "เท็จ",
            sc: "错误的",
            tc: "錯誤的",
            de: "falsch", 
            fr: "faux", 
            kr: "거짓", 
            ta: "mali",
            es: "falso",
            pt: "falso",
            hi: "झूठा",
            it: "falso",
            ar: "خطأ",
            pl: "fałsz",
            tr: "yanlış"
          }
      ],
      question: {
        en: "State whether the following statements are true or false. The statement is false.",
        ms: "Nyatakan sama ada pernyataan berikut adalah benar atau salah. Kenyataan itu palsu.",
        km: "បញ្ជាក់ថាតើសេចក្តីថ្លែងការណ៍ខាងក្រោមពិតឬមិនពិត។ សេចក្តីថ្លែងការណ៍គឺមិនពិត។",
        vi: "Cho biết các câu sau đây đúng hay sai. Tuyên bố là sai.",
        id: "Nyatakan apakah pernyataan berikut benar atau salah. Pernyataan itu salah.",
        ja: "次の記述が正しいか間違っているかを述べてください。声明は誤りです。",
        th: "ระบุว่าข้อความต่อไปนี้เป็นจริงหรือเท็จ คำสั่งเป็นเท็จ",
        sc: "请说明下列陈述是对还是错。该声明是错误的。",
        tc: "請說明下列陳述是對還是錯。該聲明是錯誤的。",
        de: "Geben Sie an, ob die folgenden Aussagen wahr oder falsch sind.Die Aussage ist falsch.",
        fr: "Indiquez si les énoncés suivants sont vrais ou faux.La déclaration est fausse.",
        kr: "다음 진술이 참인지 거짓인지를 설명하십시오.진술은 거짓입니다.",
        ta: "Sabihin kung tama o mali ang mga sumusunod na pahayag. Mali ang pahayag.",
        es: "Indica si las siguientes afirmaciones son verdaderas o falsas. La afirmación es falsa.",
        pt: "Indique se as seguintes afirmações são verdadeiras ou falsas. A afirmação é falsa.",
        hi: "बताएं कि निम्नलिखित कथन सत्य हैं या असत्य। यह कथन असत्य है।",
        it: "Indica se le seguenti affermazioni sono vere o false. L'affermazione è falsa.",
        ar: "حدد ما إذا كانت العبارات التالية صحيحة أم خاطئة. العبارة خاطئة.",
        pl: "Określ, czy poniższe stwierdzenia są prawdziwe, czy fałszywe. Stwierdzenie jest fałszywe.",
        tr: "Aşağıdaki ifadelerin doğru mu yanlış mı olduğunu belirtin. İfade yanlıştır."
      }
  },
  {
      ans_type: "Multiple Answers",
      choices: [
          "1", "2", "3", "4"
      ],
      question: {
        en: "Select all the odd numbers.",
        ms: "Pilih semua nombor ganjil.",
        km: "ជ្រើសរើសលេខសេសទាំងអស់។",
        vi: "Chọn tất cả các số lẻ.",
        id: "Pilih semua angka ganjil.",
        ja: "奇数をすべて選択します。",
        th: "เลือกเลขคี่ทั้งหมด",
        sc: "选择所有单数。",
        tc: "選擇所有單數。",
        de: "Wählen Sie alle ungeraden Zahlen aus.",
        fr: "Sélectionnez tous les nombres impairs.",
        kr: "모든 홀수를 선택하십시오.",
        ta: "Piliin ang lahat ng mga kakaibang numero.",
        es: "Selecciona todos los números impares.",
        pt: "Selecione todos os números ímpares.",
        hi: "सभी विषम संख्याओं को चुनें।",
        it: "Seleziona tutti i numeri dispari.",
        ar: "اختر جميع الأرقام الفردية.",
        pl: "Wybierz wszystkie liczby nieparzyste.",
        tr: "Tüm tek sayıları seçin."
      }
  },
  {
      ans_type: "Single Answer",
      choices: [
          {
            en: "Reading",
            ms: "Membaca",
            km: "ការអាន",
            vi: "Đọc",
            id: "Membaca",
            ja: "読む",
            th: "การอ่าน",
            sc: "阅读",
            tc: "閱讀",
            de: "Lektüre",
            fr: "En lisant",
            kr: "독서",
            ta: "Nagbabasa",
            es: "Leer",
            pt: "Lendo",
            hi: "पढ़ना",
            it: "Leggere",
            ar: "قراءة",
            pl: "Czytanie",
            tr: "Okuma"
          }, {
            en: "Gaming",
            ms: "Permainan",
            km: "ហ្គេម",
            vi: "trò chơi",
            id: "Permainan",
            ja: "ゲーム",
            th: "เกม",
            sc: "游戏",
            tc: "遊戲",
            de: "Spiele",
            fr: "Jeux",
            kr: "게임",
            ta: "Mga laro",
            es: "Juegos",
            pt: "Jogos",
            hi: "खेल",
            it: "Giocare",
            ar: "لعب",
            pl: "Gry",
            tr: "Oyun"
          }, {
            en: "Drawing",
            ms: "Melukis",
            km: "គំនូរ",
            vi: "Đang vẽ",
            id: "Menggambar",
            ja: "描く",
            th: "การวาดภาพ",
            sc: "绘画",
            tc: "繪畫",
            de: "Zeichnung",
            fr: "Dessin",
            kr: "그림",
            ta: "Pagguhit",
            es: "Dibujar",
            pt: "Desenhar",
            hi: "चित्रकारी",
            it: "Disegnare",
            ar: "رسم",
            pl: "Rysowanie",
            tr: "Çizim"
          }, {
            en: "Sleeping",
            ms: "sedang tidur",
            km: "កំពុងដេក",
            vi: "Đang ngủ",
            id: "Sedang tidur",
            ja: "睡眠",
            th: "นอนหลับ",
            sc: "睡眠",
            tc: "睡眠",
            de: "Schlafen",
            fr: "En train de dormir",
            kr: "자고 있는",
            ta: "natutulog",
            es: "Durmiendo",
            pt: "Dormindo",
            hi: "सोना",
            it: "Dormire",
            ar: "نائم",
            pl: "Spanie",
            tr: "Uyuma"
          }
      ],
      question: {
        en: "What are your hobbies and interests?",
        ms: "Apakah hobi dan minat anda?",
        km: "តើចំណង់ចំណូលចិត្ត និងចំណាប់អារម្មណ៍របស់អ្នកគឺជាអ្វី?",
        vi: "sở thích của bạn là gì?",
        id: "Apa hobi dan minat Anda?",
        ja: "あなたの趣味や興味のあるものは何ですか？",
        th: "งานอดิเรกและความสนใจของคุณคืออะไร?",
        sc: "你的爱好和兴趣是什么？",
        tc: "你的愛好和興趣是什麼？",
        de: "Was sind Ihre Hobbys und Interessen?",
        fr: "Quels sont vos hobbies et intérêts?",
        kr: "취미와 관심사는 무엇입니까?",
        ta: "anu-ano ang iyong mga hilig at interes?",
        es: "¿Cuáles son tus pasatiempos e intereses?",
        pt: "Quais são seus hobbies e interesses?",
        hi: "आपके शौक और रुचियां क्या हैं?",
        it: "Quali sono i tuoi hobby e interessi?",
        ar: "ما هي هواياتك واهتماماتك؟",
        pl: "Jakie są twoje hobby i zainteresowania?",
        tr: "Hobileriniz ve ilgi alanlarınız nelerdir?"
      }
  },
  {
      ans_type: "Multiple Answers",
      choices: [
          "5", "6", "7", "8"
      ],
      question: {
        en: "Select all the even numbers.",
        ms: "Pilih semua nombor genap.",
        km: "ជ្រើសរើសលេខគូទាំងអស់។",
        vi: "Chọn tất cả các số chẵn.",
        id: "Pilih semua bilangan genap.",
        ja: "偶数をすべて選択します。",
        th: "เลือกเลขคู่ทั้งหมด",
        sc: "选择所有双数。",
        tc: "選擇所有雙數。",
        de: "Wählen Sie alle gleichmäßigen Zahlen aus.",
        fr: "Sélectionnez tous les nombres pair.",
        kr: "모든 짝수 숫자를 선택하십시오.",
        ta: "Piliin ang lahat ng even na numero.",
        es: "Selecciona todos los números pares.",
        pt: "Selecione todos os números pares.",
        hi: "सभी सम संख्याओं को चुनें।",
        it: "Seleziona tutti i numeri pari.",
        ar: "اختر جميع الأرقام الزوجية.",
        pl: "Wybierz wszystkie liczby parzyste.",
        tr: "Tüm çift sayıları seçin."
      }
  }
];


const practiceShortQuestions = [
  {
    question: {
      en: "How are you today?",
      ms: "Apa khabar anda hari ini?",
      km: "តើថ្ងៃនេះអ្នកសុខសប្បាយជាទេ",
      vi: "Hôm nay bạn thế nào?",
      id: "Apa kabarnya hari ini?",
      ja: "今日は元気ですか？",
      th: "วันนี้สบายดีไหม",
      sc: "你今天过得怎么样？",
      tc: "你今天過得怎麼樣？",
      de: "Wie geht es dir heute?",
      fr: "Comment vas-tu aujourd'hui?",
      kr: "오늘 기분이 어떠세요?",
      ta: "Kamusta ka ngayong araw",
      es: "¿Cómo estás hoy?",
      pt: "Como você está hoje?",
      hi: "आज आप कैसे हैं?",
      it: "Come stai oggi?",
      ar: "كيف حالك اليوم؟",
      pl: "Jak się dzisiaj masz?",
      tr: "Bugün nasılsın?"
    }
  },
  {
    question: {
      en: "What are your hobbies?",
      ms: "Apakah hobi anda?",
      km: "តើអ្នកមានចំណូលចិត្តអ្វី?",
      vi: "Sở thích của bạn là gì?",
      id: "Apa hobimu?",
      ja: "趣味は何ですか？",
      th: "งานอดิเรกของคุณคืออะไร?",
      sc: "你有什么爱好？",
      tc: "你有什麼愛好？",
      de: "Was sind deine Hobbies?",
      fr: "Quels sont les hobbies?",
      kr: "취미는 무엇입니까?",
      ta: "Ano ang iyong hilig?",
      es: "¿Cuáles son tus pasatiempos?",
      pt: "Quais são seus hobbies?",
      hi: "आपके शौक क्या हैं?",
      it: "Quali sono i tuoi hobby?",
      ar: "ما هي هواياتك؟",
      pl: "Jakie są twoje hobby?",
      tr: "Hobilerin nelerdir?"
    }
  },
  {
    question: {
      en: "List one contribution in the society.",
      ms: "Senaraikan satu sumbangan dalam masyarakat.",
      km: "រាយការរួមចំណែកមួយនៅក្នុងសង្គម។",
      vi: "Liệt kê một đóng góp trong xã hội.",
      id: "Sebutkan satu kontribusi dalam masyarakat.",
      ja: "社会への貢献を 1 つ挙げてください。",
      th: "รายชื่อหนึ่งผลงานในสังคม",
      sc: "请列出一项你对社会的贡献。",
      tc: "請列出一項你對社會的貢獻。",
      de: "Listen Sie einen Beitrag in der Gesellschaft auf.",
      fr: "Énumérez une contribution dans la société.",
      kr: "사회에 하나의 기여를 나열하십시오.",
      ta: "Maglista ng isang kontribusyon sa lipunan.",
      es: "Enumera una contribución a la sociedad.",
      pt: "Liste uma contribuição para a sociedade.",
      hi: "समाज में एक योगदान सूचीबद्ध करें।",
      it: "Elenca un contributo alla società.",
      ar: "اذكر مساهمة واحدة في المجتمع.",
      pl: "Wymień jeden wkład w społeczeństwo.",
      tr: "Topluma bir katkı listeleyin."
    }
  }
];


function randomizePracticeQuestions(language, colorBlind) {
  let locale = null;
  try {
    locale = mapLocale(language)
  } catch (error) {
    throw new Error(`Interview.js: randomizePracticeQuestions(): Error mapping language: `, language, `\n Error: `, error)
  }
  if (colorBlind) {
    //To be updated for different languages
    locale = 'en_color'
  }
  if (!Object.keys(practiceQuestions).includes(locale))
    console.debug(`Interview.js: randomizePracticeQuestions(): Practice Questions for ${locale} not present in practiceQuestions object.`)

  let index = Math.floor(Math.random() * practiceQuestions[locale].length);
  console.debug(`Interview.js: randomizePracticeQuestions(): index: `, index, `\n questions: `, practiceQuestions[locale])
  return {questions: practiceQuestions[locale], randomIndex: index};
}

const interviewSlice = createSlice({
  slice: 'interview',
  initialState: initialState,
  reducers: {
    setStage(state, action) {
      state.stage = action.payload;
    },
    setVoiceIsOk(state, action) {
      const {currentQuestionTest, currentQuestionIndex} = action.payload
      state.realQuestions[currentQuestionTest][currentQuestionIndex].voiceIsOk = true
    },
    nextQuestion(state, action) {
      const idx = _.indexOf(state.unansweredQuestions, state.currentQuestion)
      if (idx < state.unansweredQuestions.length) {
        state.currentQuestion = state.unansweredQuestions[idx + 1]
        state.unansweredQuestions.shift()
      }
    },
    setFinishPractice(state, action) {
      state.finishPractice = action.payload
    },
    onUploadProgress(state, action) {
      state.uploadProgress = action.payload
    },
    onUploadCompletedTrue(state, action) {
      state.uploadCompleted = [...state.uploadCompleted, true]
    },
    onJobseekerGetProfileStarted(state, action) {
      state.getProfileInProgress = true;
    },
    onJobseekerGetProfileSuccess(state, action) {
      state.getProfileInProgress = false;
      const profile = action.payload;
      // Update State for Profile Info
      state.infoUploaded = profile.infoUploaded
      state.deadline = profile.deadline;
      state.interviewJobTitle = profile.jobTitle;
      state.interviewLanguage = profile.interviewLanguage;
      state.interviewLanguageVariant = profile.interviewLanguageVariant
      state.jobId = profile.jobId;
      state.fullname = profile.fullname;
      state.interviewId = profile.interviewId;
      state.companyLogo = profile.companyLogo;
      state.interviewCompany = profile.companyName;
      state.totalQuestion = profile.totalQuestion;
      state.colorScheme = profile.colorScheme;
      state.avatar = profile.avatar;
      state.showAvatarIdentity = profile.showAvatarIdentity
      state.useVirtualAvatar = profile.useVirtualAvatar;
      state.virtualAvatar = profile.virtualAvatar;
      state.virtualAvatars = profile.virtualAvatars;
      state.email = profile.email;
      // state.displayLayout = profile.displayLayout
      // state.preparationTimeLimit = profile.preparationTimeLimit
      state.candidate_upload_cv_required = profile.candidate_upload_cv_required

      state.timeLimitPerQuestion = profile.colorBlindTest === 1 ? 1 / 3 : profile.questionLength;
      state.timeLimit = profile.totalQuestion * state.timeLimitPerQuestion;
      state.realQuestions = profile.realQuestions;
      state.practiceQuestions = profile.practiceQuestions
      state.practiceMCQs = profile.practiceMCQs
      state.practiceShortQuestions = profile.practiceShortQuestions
      state.practiceAudio = profile.practiceAudio
      state.totalPracticeQuestion = state.practiceQuestions.length;
      state.hasVideoIntroduction = profile.hasVideoIntroduction;
      state.questionDetail = profile.questionDetail
      state.colorBlindTest = profile.colorBlindTest === 1
      state.displayLayout = profile.displayLayout
      state.interviewerAvatar = profile.interviewerAvatar
      state.interviewerFirstname = profile.interviewerFirstname
      state.interviewerLastname = profile.interviewerLastname
      state.interviewerPosition = profile.interviewerPosition
      state.preparationTimeLimit = profile.preparationTimeLimit
      state.companyWebsite = profile.companyWebsite
      state.remarkCode = profile.remarkCode
      state.multiQuestionsAnswers = profile.multiQuestionsAnswers
      state.currentQuestionTest = profile.currentQuestionTest
      state.email = profile.email
      state.consentEnabled = profile.consentEnabled
      state.consentContent = profile.consentContent
      state.consentID = profile.consentID
      state.consentGiven = profile.consentGiven
      state.consentApiMessage = profile.consentApiMessage
      state.unansweredQuestions = _.get(profile, 'unansweredQuestions',
        _.range(_.get(profile, 'currentQuestion', 0), profile.totalQuestion),
      )
      /**
       * Additional state set to the array of currently unanswered questions:
       * 1. Not updated within the current login session
       * 2. Used to track video interview upload progress (legacy feature that was implemented for async video upload)
       */
      state.currestSessionUploadList = state.unansweredQuestions 
      state.currentQuestion = _.get(profile, 'currentQuestion', state.unansweredQuestions[0])
      if (!_.includes(state.unansweredQuestions, state.currentQuestion)) {
        state.currentQuestion = state.unansweredQuestions[0]
      }
      state.retake = _.get(profile, 'retake', 0)
      state.retry = _.get(profile, 'retry', 0)
      state.multiQuestions = profile.multiQuestions
      state.company_video = profile.company_video
      if (profile.multiQuestions.length == 1 && profile.multiQuestions[0].type == "Video Interview"){
        state.old="_old"
      }
      state.currentVideoInterviewRetryCount = profile.currentVideoInterviewRetryCount
      state.defaultTriggered = profile.defaultTriggered
      state.quotaCounted = profile.quotaCounted ?? 0
    },
    onJobseekerGetSettingSuccess(state, action){
      state.getProfileInProgress = false
      const setting = action.payload

      state.colorScheme = setting.colorScheme;
      
      state.companyLogo = setting.companyLogo;
      state.interviewCompany = setting.companyName;
      state.companyWebsite = setting.companyWebsite;
      state.consentEnabled = setting.consentEnabled;
      state.jobId = setting.jobId;
      state.interviewJobTitle = setting.jobTitle;
      state.interviewLanguage = setting.interviewLanguage;
      state.interviewLanguageList = setting.interviewLanguageList;
      state.hasVideoIntroduction = setting.hasVideoIntroduction;
      state.candidate_upload_cv_required = setting.candidate_upload_cv_required;
      state.company_video = setting.company_video;
    },
    onJobSeekerUpdateHomeScreen(state, action){
      state.interviewHomeScreen = action.payload
    },
    onJobSeekerResetHomeScreen(state, action){
      state.interviewHomeScreen = null
    },
    onJobSeekerUpdateEmailVerification(state, action){
      state.emailVerification = action.payload
    },
    onJobseekerGetProfileFailed(state, action) {
      state.getProfileInProgress = false;
    },
    /**
     * Update: 19 April 2024
     * TODO: Remove deprecated functionality
     */
    onVideoSubmissionStarted(state, action) {
      if (action.payload.updateStatus) {
        // reset submission if it's already in the list
        state.videoSubmissionStatuses = [{
          uploadId: action.payload.uploadId,
          status: 'uploading',
        }, ..._.filter(state.videoSubmissionStatuses, e => e.uploadId !== action.payload.uploadId)]
      }
    },
    /**
     * Update: 19 April 2024
     * TODO: Remove deprecated functionality
     */
    onVideoSubmissionSuccess(state, action) {
      if (action.payload.updateStatus) {
        state.videoSubmissionStatuses = [{
          uploadId: action.payload.uploadId,
          status: 'uploaded',
        }, ..._.filter(state.videoSubmissionStatuses, e => e.uploadId !== action.payload.uploadId)]
      }
    },
    /**
     * Update: 19 April 2024
     * TODO: Remove deprecated functionality
     */
    onVideoSubmissionFailed(state, action) {
      state.videoSubmissionError = action.payload.message;
      if (action.payload.updateStatus) {
        state.videoSubmissionStatuses = [{
          uploadId: action.payload.uploadId,
          // status: _.get(action, 'payload.blobsize', 0) === 0 ? 'failed' : 'unknown',
        }, ..._.filter(state.videoSubmissionStatuses, e => e.uploadId !== action.payload.uploadId)]
      }
    },
    setSampleRate(state, action) {
      state.sampleRate = action.payload
    },

    onGetS3VideoStatusesStarted(state, action) {
      state.gettingS3VideoStatuses = true
      state.s3VideoStatuses = null
    },
    onGetS3VideoStatusesSuccess(state, action) {
      state.gettingS3VideoStatuses = false
      state.s3VideoStatuses = _.get(action, 'payload', {
        files: [],
        s3files: [],
      })
    },
    onGetS3VideoStatusesFailed(state, action) {
      state.gettingS3VideoStatuses = false
      state.s3VideoStatuses = {
        files: [],
        s3files: [],
      }
    },
    onSubmitMultiquestionAnswersStarted(state, action) {
        state.submittingAnswers = true
    },
    onSubmitMultiquestionAnswersSuccess(state, action) {
        state.submittingAnswers = false
    },
    onSubmitMultiquestionAnswersFailed(state, action){
        state.submittingAnswers = false
    },
    onSettingTestDeadline(state, action){
        state.settingTestDeadline = true
        const {currentQuestionTest, testDeadline} = action.payload
        state.multiQuestions[currentQuestionTest].testDeadline = testDeadline
    },
    onSettingTestDeadlineSuccess(state, action){
        state.settingTestDeadline = false
    },
    onSettingTestDeadlineFailed(state,action){
        state.settingTestDeadline = false
    },
    onUpdatingTestStatus(state, action) {
        state.updatingStatus = true
    },
    onUpdatingTestStatusSuccess(state, action){
        state.updatingStatus = false
    },
    onUpdatingTestStatusFailed(state, action){
        state.updatingStatus = false
    },
    setCurrentTest(state, action){
        state.currentQuestionTest = action.payload
    },
    onUpdatingCurrentQuestion(state, action){
        state.updatingCurrentQuestion = true
    },
    onUpdatingCurrentQuestionSuccess(state, action){
        state.updatingCurrentQuestion = false
    },
    onUpdatingCurrentQuestionFailed(state, action){
        state.updatingCurrentQuestion = false
    },
    updatingMultiQuestionAnswers(state, action){
        const {currentQuestionTest, currentQuestion, answer} = action.payload
        state.multiQuestionsAnswers[currentQuestionTest].answers_of_multiQuestions[currentQuestion].answer = answer
    },
    updatingConsentGiven(state, action) {
      state.updatingConsentStatus = true
    },
    updatingConsentGivenSuccess(state, action){
      // console.log("interview.js: updatingConsentGiven: action", action.payload)
      let {message, status} = action.payload
      // console.log("interview.js: updatingConsentGiven: ",message, status)
      state.consentGiven = message
      state.consentApiMessage = status
      state.updatingConsentStatus = false
      // console.log("interview.js: updatingConsentGiven: ",state.consentGiven, state.consentApiMessage)
    },
    updatingConsentGivenFailed(state, action) {
      state.updatingConsentStatus = false
    },
    updatingInfoUploadedStatus(state, action) {
      state.infoUploaded = action.payload
    },
    onUpdatingTempAccessToken(state, action){
      state.tempAccessTokenForLinkLogin = action.payload
    },
    updateVideoInterviewRetryCount(state, action) {
      const apiResponse = action.payload
      state.currentVideoInterviewRetryCount = apiResponse
    },
    updatingDefaultTriggeredStatus(state, action) {
      state.defaultTriggered = action.payload.error_code
    },
    resetDefaultTriggeredStatus(state, action){
      state.defaultTriggered = 0
    },
    recordFirstTestAttemptSuccess(state, action) {
      state.quotaCounted = 1
    }
  }
});

function generateInterviewId(job_id) {
  const hash = sha1(`${job_id}`);
  return `interview_${hash.substring(0, 8)}_${Date.now()}`;
}

// export const resetDefaultTriggered = (token) => async dispatch => {
//   dispatch(resetDefaultTriggeredStatus())
//   const payload = {
//     "token": token
//   }
//   const response = await resetDefaultTriggeredStatusApiCall(payload)
//   return response

// }

export const getInterviewstatus = (token, currentQuestion) => async dispatch => {
  const getInterview = await getInterviewStarted(token, currentQuestion);
  return getInterview;
}

export const getInterviewVideoStatus = (token, question_id, attempt_number, file_size) => async (dispatch) => {
    const getInterviewVideoStatus = await postInterviewVideoStatus(
      token,
      question_id,
      attempt_number,
      file_size
    );
    return getInterviewVideoStatus;
  };

export const callAudioProcessingLambda = (token, filePath, currentQuestionIndex, answerLanguage) => async (dispatch, getState) => {
  const {
    interview,
  } = getState();
  return await triggerAudioProcessingLambda(
    token,
    BUCKETS.ORIGINAL_VIDEO_BUCKET,
    filePath
  )
}

export const moveToNextQuestion = (token, num) => async dispatch => {
  const response = await updateUnansweredQuestions(token, num)
  if(response.ok){
    if(response.obj && response.obj.error_code === 0){
      dispatch(resetDefaultTriggeredStatus())
      dispatch(nextQuestion())
    } else {
      console.error("Error with API call")
    }
  } else {
    console.error("Error with updating Unanswered Questions Array")
  }
}

export const moveToFinish = (token, num) => async dispatch => {
  const response = await updateUnansweredQuestions(token, num)
  if(response.ok){
    if(response.obj && response.obj.error_code === 0){
      console.log("Successfully made API Call")
    } else {
      console.error("Error with API call")
    }
  } else {
    console.error("Error with updating Unanswered Questions Array")
  }
}

export const getProfile = (token, language) => async dispatch => {
  try {
    console.time("Whole Process")
    dispatch(onJobseekerGetProfileStarted());
    const profile = {};
    console.time('Get Status')
    let currentStatusResponse;
    try{
      currentStatusResponse = await fetchStatus(token);
    } catch(err){
      console.debug("Interview.js: currentStatusResponse Error: ", err)
    }
    console.timeEnd('Get Status')
    
    if (currentStatusResponse && currentStatusResponse.obj.error_code === 0) {
      const currentStatus = currentStatusResponse.obj;
      console.log("currentStatus: ", currentStatus)
      if (!currentStatus.result.question_set && !currentStatus.result.multiQuestions) {
        await assignQuestion(token);
        await submitMultiquestionAnswersApi(null, token, 0, 0) //generate report for candidate
        currentStatusResponse = await fetchStatus(token);
      }
      profile.jobId = currentStatus.result.job_id;
      profile.email = currentStatus.result.email
      if (currentStatus.result.interview_id) {
        profile.interviewId = currentStatus.result.interview_id;
      } else {
        profile.interviewId = generateInterviewId(currentStatus.result.job_id);
        console.debug("Interview.js: Interview Id absent. Creating one now")
      }
      profile.currentQuestion = currentStatus.result.current_question;
      profile.fullname = currentStatus.result.fullname;
      profile.email = currentStatus.result.email;
      profile.currentVideoInterviewRetryCount = currentStatus.result?.video_interview_tracker_count ?? 0
      profile.infoUploaded = "info_uploaded" in currentStatus.result ? currentStatus.result.info_uploaded : 0;
      profile.defaultTriggered = currentStatus.result?.default_triggered ?? 0
    } else {
      dispatch(onJobseekerGetProfileFailed());
      console.debug("Interview.js: Failed Retrieving Error Response")
      return null
    }
    
    profile.language = language;
    
    console.time('Get Setting')
    const currentSettingResponse = await fetchSetting(token);
    console.log("currentSettingResponse: ", currentSettingResponse)
    console.timeEnd('Get Setting')
    const multiquestionAnswerResponse = await fetchMultiquestionAnswersApi(token);
    if (currentSettingResponse && currentSettingResponse.obj.error_code === 0) {
      let currentSetting = currentSettingResponse.obj.result;
      let consentEnabled = currentSetting.consentEnabled
      console.log("consentEnabled: ", consentEnabled)
      try{
        if(consentEnabled){
          let checkConsentResponse = await checkCandidateConsentStatus(token)
          console.log("interview.js: checkConsentResponse.body: ", checkConsentResponse.body.message, checkConsentResponse.body.status)
          if (checkConsentResponse &&
            checkConsentResponse.body.error_code === 0
          ){ 
            console.log("checkConsentResponse: ", checkConsentResponse)
            profile.consentGiven = checkConsentResponse.body.message
            profile.consentApiMessage = checkConsentResponse.body.status
            if (profile.consentApiMessage === "Consent rejected") return
            // dispatch(updatingConsentGiven({
            //   message: checkConsentResponse.body.message,
            //   status: checkConsentResponse.body.status
            // }))
          } else {
            throw new Error("Error in the check consent status")
          }
        }
      } catch(e){
          console.log(e)
          profile.consentGiven = false
          profile.consentApiMessage = ""
      }
     
      
      profile.candidate_upload_cv_required = "candidate_upload_cv_required" in currentSetting ? currentSetting.candidate_upload_cv_required : false;
      profile.companyName = currentSetting.company_name
      profile.hasVideoIntroduction = (currentSetting.video !== null && currentSetting.video.length > 0);
      profile.company_video = profile.hasVideoIntroduction ? await gets3object(currentSetting.video, token, BUCKETS.MEDIA_BUCKET): false;
      profile.deadline = currentSetting.deadline;
      profile.jobTitle = currentSetting.jobtitle;
      profile.consentEnabled = consentEnabled;
      try {
        if (consentEnabled) {
          // If consent is Enabled do fetching
          let consentResponse = await getConsentCandidate(token)
          if (consentResponse.ok && 
            consentResponse.body.error_code === 0 &&
            consentResponse.body.status === 'success'
            ) {
              profile.consentContent = consentResponse.body.message.consent_data
              profile.consentID = consentResponse.body.message.consent_id
            }
        }
      } catch (e) {
        console.log(e)
        profile.consentContent  = "<p></p>"
        profile.consentID = ""
      }
    
      try{
        profile.companyLogo = currentSetting.company_logo != "null" ? await gets3object(currentSetting.company_logo, token, BUCKETS.MEDIA_BUCKET) : await gets3object('company_logos/neufast_logo.png', token, BUCKETS.MEDIA_BUCKET) //`${config.baseUrl}/api/jobseeker/get_logo/${profile.jobId}`;
      }catch{
        profile.companyLogo = await gets3object('company_logos/neufast_logo.png', token, BUCKETS.MEDIA_BUCKET)
      }
      profile.interviewLanguage = currentSetting.language;
      profile.interviewLanguageVariant = currentSetting.language_variant
      profile.questionLength = currentSetting.question_length;
      profile.avatar = (currentSetting.arator === 'yes');
      profile.showAvatarIdentity = !!currentSetting.show_avatar_identity
      profile.displayLayout = currentSetting.layout_type === '1' ? interviewLayout.SIDE_BY_SIDE : interviewLayout.PICTURE_IN_PICTURE
      // profile.interviewerAvatar = `${config.baseUrl}/api/jobseeker/get_avatar_image/${profile.jobId}`;
      try {
        if (currentSetting.avatar_image) {
          profile.interviewerAvatar =  await gets3object(currentSetting.avatar_image, token, BUCKETS.MEDIA_BUCKET)  //`${config.baseUrl}/api/jobseeker/get_avatar_image/${profile.jobId}`;
        }
      } catch (error) {
        console.debug("Interview.js: interviewAvatar : ", error)
      }
      profile.interviewerFirstname = currentSetting.avatar_information.first_name
      profile.interviewerLastname = currentSetting.avatar_information.last_name
      profile.interviewerPosition = currentSetting.avatar_information.job_title
      profile.useVirtualAvatar = currentSetting.use_virtual_avatar
      profile.virtualAvatar = currentSetting.virtual_avatar
      try {
        console.log("virtual avatar")
        if (profile.useVirtualAvatar) {
          console.log("fetching virtual avatars")
          const avatarResponse = await fetchVirtualAvatars(token)
          if (avatarResponse.body.error_code !== 0) {
            console.error("Error fetching virtual avatars", avatarResponse.body)
          }
          profile.virtualAvatars = avatarResponse.body.virtual_avatars
        }
      } catch (err) {
        console.error("Error fetching virtual avatar:", err)
      }
      profile.preparationTimeLimit = currentSetting.preparation_time
      profile.companyWebsite = currentSetting.company_website
      profile.colorBlindTest = currentSetting.color_blind_test ? currentSetting.color_blind_test : 0
      profile.remarkCode = currentSetting.remark_code
      profile.multiQuestions = currentSetting.multiQuestions
      // if (currentSetting.colour_scheme && currentSetting.colour_scheme.startsWith('#')) {
      //   profile.colorScheme = currentSetting.colour_scheme;
      // } else {
      //   profile.colorScheme = '#022D41';
      // }
      console.log("colorScheme: normal ", currentSetting.colour_scheme)
      if (currentSetting.colour_scheme) {
        let colorScheme = currentSetting.colour_scheme;
        console.log("colorScheme: normal: before", colorScheme)
        if (!currentSetting.colour_scheme.startsWith('#')){
          colorScheme = rgbaToHex(colorScheme)
        }
        console.log("colorScheme: normal: after", colorScheme)
        profile.colorScheme = colorScheme;
      } else {
        profile.colorScheme = '#022D41';
      }

      var questions = currentSetting.question

      var allAnswers = []
      console.log("multiquestionAnswerResponse: ", multiquestionAnswerResponse)
      console.log("profile: ", profile)
      if (multiquestionAnswerResponse.obj.error_code === 4){
        profile.multiQuestions.forEach((element, index) => {
            if(element.type === "Multiple Choices"){
                allAnswers.push({
                    "type": element.type,
                    "answers_of_multiQuestions": Array(element.questions_num).fill({answer: []})
                })
            }else{
                allAnswers.push({
                    "type": element.type,
                    "answers_of_multiQuestions": Array(element.questions_num).fill({answer: null})
                })
            }   
        })
      }else{
        allAnswers = [...multiquestionAnswerResponse.obj]
        console.debug("Interview.js: allAnswers : else", allAnswers)
      }

      profile.multiQuestionsAnswers = allAnswers
      if(currentSetting.multiQuestions.length > 0){
        const questionPromise = []
        currentSetting.multiQuestions.forEach((questionType, index) => {
            if(questionType.type === "Video Interview"){
                profile.totalQuestion = questions[index].length;  //totalQuestion of Video Interview
                questionPromise.push(triggerTextToSpeechAll(questions[index], profile.interviewLanguage, token).then(res => {
                    questions[index] = res.obj
                    console.debug("Interview.js: Text to Speech: if", res.obj)
                    for (var question of questions[index]){
                      const audio = `${config.baseUrl}/api/voice/voice/${question['audioFile']}`;
                      question.audio = audio;
                    }
                  }),
                  rej => {
                    console.debug("Interview.js: Error in Promise")
                })
              }
            })
            await Promise.all(questionPromise)
          }else{
            const questionResponse = await triggerTextToSpeechAll(questions[0].questions, profile.interviewLanguage, token)
            console.debug("Interview.js: Text to Speech: else", questionResponse)
          if (questionResponse && questionResponse.ok) {
            questions[0] = questionResponse.obj
            for (var question of questions[0]) {
              const audio = `${config.baseUrl}/api/voice/voice/${question['audioFile']}`;
              question.audio = audio;
            }
        }
      }

      // for (var question of questions) {
      //   console.time(`Get Question Audio - ${question}`)
      //   if (question.openAudio !== false && !question.audio) {
      //     const response = await triggerTextToSpeech(question.question, profile.interviewLanguage)
      //     const audioFile = response.obj.filename;
      //     const audio = `${config.baseUrl}/api/voice/voice/${audioFile}`;
      //     question.audio = audio;
      //   }
      //   console.timeEnd(`Get Question Audio - ${question}`)
      // }
      const bucketName = {
        "Video Interview": BUCKETS["RECRUITER_DATA"], "Multiple Choices": BUCKETS.MEDIA_BUCKET, "Short Question": BUCKETS.MEDIA_BUCKET
      }

      const pathName = {
          "Video Interview": "", "Multiple Choices": "mcq_media/", "Short Question": "short_question_media/"
      }
      await questions.forEach((ele,index)=>{
        ele.forEach(async e=>{
          if (e.filename){
            const testmap = profile.multiQuestions[index].type  
            e.file = await gets3object(`${pathName[testmap]}${e.filename}`, token, bucketName[testmap]);
          }})
      })
      profile.realQuestions = [...questions]
      let index = 0
      try{
        let listOfQuestions = randomizePracticeQuestions(profile.interviewLanguage, profile.colorBlindTest);
        profile.practiceQuestions = listOfQuestions.questions
        index = listOfQuestions.randomIndex
      } catch(error){
        let listOfQuestions = randomizePracticeQuestions(`english`, profile.colorBlindTest);
        profile.practiceQuestions = listOfQuestions.questions
        index = listOfQuestions.randomIndex
        console.debug("Interview.js: fail: randomising practice question: ", error, "\n Index: ", index)
      }
      let response;
      try {
        response = await triggerTextToSpeech(profile.practiceQuestions[index], profile.interviewLanguage, token)
      } catch (error) {
        console.debug("Interview.js: fail: text to speech: ", error)
      }
      const audioFile = response.obj.filename;
      try{
        profile.practiceAudio = `${config.baseUrl}/api/voice/voice/${audioFile}`;
      } catch(error){
        console.debug("Interview.js: fail: practiceAudio ", error)
      }
      profile.practiceMCQs = practiceMCQs;
      profile.practiceShortQuestions = practiceShortQuestions;
      profile.multiQuestions.map((element, index) => {
        if (element.started == true && element.finished == false){
            profile.currentQuestionTest = index
        } 
      })
      
      if(profile.currentQuestionTest === null || profile.currentQuestionTest === undefined){
        profile.currentQuestionTest = profile.multiQuestions.length - 1
      }
    } else {
      dispatch(onJobseekerGetProfileFailed());
      console.debug("Interview.js: failed in  dispatching onJobseekerGetProfileFailed")
      return null
    }

    if(currentStatusResponse.obj.result.unanswered_questions){
        profile.unansweredQuestions = _.get(currentStatusResponse, 'obj.result.unanswered_questions',
        _.range(_.get(profile, 'currentQuestion', 0), profile.totalQuestion),
        )
        if (!_.includes(profile.unansweredQuestions, profile.currentQuestion)) {
        profile.currentQuestion = profile.unansweredQuestions[0]
        }
    }

    profile.retake = _.get(currentStatusResponse, 'obj.result.retake', 0)
    profile.retry = _.get(currentStatusResponse, 'obj.result.retry', 0)

    console.timeEnd("Whole Process")
    dispatch(onJobseekerGetProfileSuccess(profile));
    return profile;
  } catch (err) {
    console.log("error: ", err)
    await sendLog(token, err.toString())
    dispatch(onJobseekerGetProfileFailed({
      message: err.toString()
    }));
    return false;
  }
};

const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

const rgbaToHex = (rgbaString) => {
  // Extract the RGBA values from the string
  const rgbaValues = rgbaString.match(/(\d+(\.\d+)?)/g);
  // Convert the RGB values to integers
  const r = parseInt(rgbaValues[0]);
  const g = parseInt(rgbaValues[1]);
  const b = parseInt(rgbaValues[2]);
  const a = Math.round(parseFloat(rgbaValues[3]) * 255); // Convert alpha value to integer in range [0, 255]

  // Convert the RGB values to hexadecimal
  const hexValue = "#" + componentToHex(r) + componentToHex(g) + componentToHex(b) + componentToHex(a);

  return hexValue;

}

export const getJobSettingInDangerousEnvironment = (token, jobId, language) => async dispatch => {
  try{
    console.log("Interview.jsx: token: ", token, " jobId: ", jobId, " language: ", language)
    console.time("Whole Process: Retrieve Job Setting in Dangerous environments")
    dispatch(onJobseekerGetProfileStarted());
    const profile = {}
    profile.language = language;

    console.time('Get Setting: Retrieve Job Setting in Dangerous environments ')

    const currentSettingResponse = await fetchSettingInDangerousEnvironment(token, jobId);
    console.log("interview.jsx: currentSettingResponse: ", currentSettingResponse)
    if (currentSettingResponse && currentSettingResponse.obj.error_code === 0) {
      let currentSetting = currentSettingResponse.obj.message;
      let s3token = currentSettingResponse.obj.token;
      console.log("interview.jsx: currentSetting: ", currentSetting)

      profile.jobTitle = currentSetting.jobtitle;
      profile.jobId = currentSetting.job_id;
      
      // Retrieve Colour
      console.log("colorScheme: unsafe ", currentSetting.colour_scheme)
      if (currentSetting.colour_scheme) {
        let colorScheme = currentSetting.colour_scheme;
        console.log("colorScheme: unsafe: before", colorScheme)
        if (!currentSetting.colour_scheme.startsWith('#')){
          colorScheme = rgbaToHex(colorScheme)
        }
        console.log("colorScheme: unsafe: after ", colorScheme)
        profile.colorScheme = colorScheme;
      } else {
        profile.colorScheme = '#022D41';
      }
      
      if (currentSetting.language){
        profile.interviewLanguage = currentSetting.language
      }
      if (currentSetting.consentEnabled){
        profile.consentEnabled = currentSetting.consentEnabled
      } else {
        profile.consentEnabled = 0
      }
      // Retrieve company Information
      profile.interviewLanguageList = currentSetting.interview_languages
      profile.companyName = currentSetting.company_name
      profile.companyWebsite = currentSetting.company_website
      profile.remarkCode = currentSetting.remark_code
      try{
        profile.companyLogo = currentSetting.company_logo !== "null" ? await gets3objectWithJobId(currentSetting.company_logo, s3token, BUCKETS.MEDIA_BUCKET) : await gets3objectWithJobId('company_logos/neufast_logo.png', s3token, BUCKETS.MEDIA_BUCKET) //`${config.baseUrl}/api/jobseeker/get_logo/${profile.jobId}`;
      }catch{
        profile.companyLogo = await gets3objectWithJobId('company_logos/neufast_logo.png', s3token, BUCKETS.MEDIA_BUCKET)
      }
      profile.candidate_upload_cv_required = "candidate_upload_cv_required" in currentSetting ? currentSetting.candidate_upload_cv_required : false;
      profile.hasVideoIntroduction = (currentSetting.company_video !== null && currentSetting.company_video.length > 0);
      profile.company_video = profile.hasVideoIntroduction ? await gets3objectWithJobId(currentSetting.company_video, s3token, BUCKETS.MEDIA_BUCKET): false;
    } else {
      dispatch(onJobseekerGetProfileFailed());
      console.debug("Interview.js: Failed Retrieving Error Response")
      return null
    }

    console.timeEnd('Get Setting: Retrieve Job Setting in Dangerous environments')

    console.timeEnd("Whole Process: Retrieve Job Setting in Dangerous")
    dispatch(onJobseekerGetSettingSuccess(profile));
    console.log(profile)
    return profile;
  } catch (err) {
    console.log("error: ", err)
    dispatch(onJobseekerGetProfileFailed({
      message: err.toString()
    }));
    return false;
  }
}
export const sendToBtob = (token, answer, answerLanguage) => async (dispatch, getState) => {
  const {
    interview,
    authenticated
  } = getState();
  
  // return 0
  let response = null;
  let errorMessage = ''
  let uploadId =
    interview.stage === 'interview'
    ? interview.currentQuestion
    : interview.stage;
  // dispatch(onVideoSubmissionStarted({ uploadId, updateStatus: interview.stage === 'interview' }));
  try {
    console.log("sendToBtob: attempting to create upload_videos doc")
    // if (_.get(blob, 'size', 0) <= 0) {
    //   errorMessage = 'video size <= 0'
    // }
    if (interview.stage === 'setup') {
      // await check({
      //   t: 0,
      //   n: 'setup'
      // }) // mark upload start time
      // const starttime = Date.now()
      // response = await uploadTest(blob);
      // const timeTaken = Date.now() - starttime
      // console.debug({
      //   timeTaken
      // })
      // await check({
      //   t: timeTaken,
      //   n: 'setup'
      // }) // mark time taken for upload
    } else if (interview.stage === 'rehearsal') {
      console.log("sendToBtob: state: rehearsal")
      response = await uploadPractice(token, interview.practiceQuestions[0], interview.jobId, interview.interviewLanguage);
      return response
    } else if (interview.stage === 'interview') {
      console.log("sendToBtob: state: interview")
      const isFinish = interview.currentQuestion === interview.totalQuestion - 1;
      const starttime = Date.now()
      response = await uploadVideo(
        // blob,
        token,
        interview.realQuestions[interview.currentQuestionTest][interview.currentQuestion].question,
        interview.jobId,
        interview.totalQuestion,
        interview.currentQuestion + 1,
        interview.interviewId,
        isFinish,
        //interview.interviewLanguage
        interview.realQuestions[interview.currentQuestionTest][interview.currentQuestion].lang,
        answer
      )
      console.log("sendToBtob: reponse: ", response)
      if (response?.error_code !== 0){
        console.log("Interview.js: sendToBtob: response: ", response);
        // await sendLog(token, response);
        // errorMessage = response
        throw new Error(`Error in send to btob ${JSON.stringify(response)}`);
      }
      const timeTaken = Date.now() - starttime
      console.log('Time Taken: ', timeTaken)
      // if (response.ok && response.obj) {
      //   var code = response.obj.message
      //   console.log("recall: " + code)
      //   var result = null
      //   if (code) {
      //     do {
      //       await sleep(2000)
      //       result = await getTask(code)
      //       if (result.ok && result.obj) {
      //         log.info("checkState: ")
      //         log.info(result.obj)
      //         success = result.obj.task_status !== "failed"
      //       }
      //     } while (result && result.obj && result.obj.task_status && result.obj.task_status !== "failed" && result.obj.task_status !== "finished" && result.obj.task_status !== "queued" && result.obj.task_status !== 'started')
      //   }
      //   if (!success) {
      //     await sleep(10000)
      //   } else {
      //     break
      //   }
      // }
      // }
      return response;
      }
    // if interview.stage is an unknown value
    return response;

  } catch (err) {
    console.error(err)
    await sendLog(token, err.toString())
    return response
  }
}

// export const checkIfVoicePresent = (token, currentQuestionIndex) => async (dispatch, getState) => {
//   const {
//     interview,
//     authenticated
//   } = getState()

//   let questions = interview.realQuestions[interview.currentQuestionTest]
//   let answerLanguage = questions[currentQuestionIndex].lang || 'english'
//   const voiceCheck = await fetchVoiceActivationDetectionStatus(token, interview.interviewId, currentQuestionIndex + 1, answerLanguage)
//   const voiceCheckResponseBody = voiceCheck?.body
//   console.log("voice detection response: ", voiceCheckResponseBody)
//   if (voiceCheckResponseBody){
//     return voiceCheckResponseBody
//   } else return {
//     "error_code": 9,
//     "check": false,
//     "speech_duration": 0
//   }
// }

export const submitVideo = (token, blob, answer, answerLanguage, videoFormat) => async (dispatch, getState) => {
  const {
    interview,
    authenticated
  } = getState();

  try {
    var filePath = `orgnl$${interview.interviewId}$${interview.currentQuestion + 1}$${answerLanguage}.webm`
    /**
     * 1. create upload to aws
     */
    const getUploadId = await create_upload(filePath, token, BUCKETS.ORIGINAL_VIDEO_BUCKET)
    if (!getUploadId?.body?.upload_id) {
      let msg = `Creating upload id failed: getUploadId: ${getUploadId} | filePath: ${filePath}`
      throw new Error(`Failed to create upload id - Error: ${JSON.stringify(getUploadId)}. ${msg}`);
    }
    const uploadId = getUploadId.body.upload_id

    //start multipart upload
    const FILE_CHUNK_SIZE = 10000000 // 10MB
    const fileSize = blob.size
    const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1
    let promisesArray = []
    let start, end, blobfile

    // let setup_throw_error = 0
    axiosRetry(axios, { 
      retries: 6,
      retryDelay: () => axiosRetry.exponentialDelay,
      onRetry: (retryCount, error) => {
        console.log(`submitVideo function: Retry attempt: ${retryCount} | error: ${error.toString()}`)
      }
    });
    
    var propgressarr = []
    for (let index = 1; index < NUM_CHUNKS + 1; index++) {
      const config = {
        headers: {
          'content-type': blob.type
        },
        onUploadProgress: progress => {
          let questionNum = _.indexOf(interview.currestSessionUploadList, interview.currentQuestion)
          let progressLoaded = Math.round(progress.loaded * 100 / progress.total);
          propgressarr[index - 1] = progressLoaded // this video's progress
          let average = propgressarr.reduce((a, b) => a + b) / propgressarr.length;

          var newFile = Object.assign([], getState().interview.uploadProgress)
          newFile[questionNum] = Math.round(average)
          dispatch(onUploadProgress(newFile))
        }
      }
      // setup_throw_error += 1
      start = (index - 1) * FILE_CHUNK_SIZE
      end = (index) * FILE_CHUNK_SIZE
      blobfile = (index < NUM_CHUNKS) ? blob.slice(start, end) : blob.slice(start)
      // let uploadResp;
      // try{
        let getUploadLink = await multi_upload(filePath, uploadId, index, token, BUCKETS.ORIGINAL_VIDEO_BUCKET)
        if (!getUploadLink?.body?.signed_url){
          let msg = `Creating presigned url failed: getUploadLink: ${getUploadLink} | filePath: ${filePath} | uploadId: ${uploadId} | index: ${index}`
          throw new Error(`Failed to get presignedUrl - Error: ${JSON.stringify(getUploadLink)}. ${msg}`);
        }
      let presignedUrl = getUploadLink.body.signed_url
      /**
       * 2. Upload the file chunks to the presigned URL
       */
      let uploadResp = axios.put(presignedUrl, blobfile, config)
        promisesArray.push(uploadResp)
      }

      let resolvedArray;
      let putMsg = `Upload Started: objectName: ${filePath} | uploadId: ${uploadId} | promisesArray: ${promisesArray.length}`
      try {
        resolvedArray = await Promise.all(promisesArray)

        putMsg = `Upload Success : objectName: ${filePath} | resolvedPromises: ${JSON.stringify(resolvedArray.map( e => ({
          [e['headers']['etag'].trim().slice(1,-1)]: {
            contentType: e['config']['headers']['Content-Type'],
            axiosRetry: e['config']['axios-retry']}
          })))}}`
          
        sendLog(token, putMsg)
        console.log(`submitVideo function: Upload Success: objectName: ${filePath}`)
      } catch(err){
        putMsg = `Upload Failed: objectName: ${filePath} | response: ${err.toString()}`
        console.log(`submitVideo function: Upload Failed: objectName: ${filePath} | response: ${err.toString()}`)
        throw new Error(`Failed to upload all parts - Error: ${JSON.stringify(resolvedArray)}. ${putMsg}`);
      }
    let uploadPartsArray = []
    // Async safe Loop
    for await(const [index, resolvedPromise] of resolvedArray.entries()){
      let item = {
            ETag: resolvedPromise.headers.etag,
            PartNumber: index + 1
          }
          uploadPartsArray.push(item)
    }
    /**
     * 3. Complete the multipart upload
     */
    // setTimeout(async() => {
    const completeUploadResp = await complete_upload(filePath, uploadId, uploadPartsArray, token, BUCKETS.ORIGINAL_VIDEO_BUCKET, "yes")
    console.log("complete upload response", completeUploadResp?.body?.ResponseMetadata?.HTTPStatusCode)
    if (completeUploadResp?.body?.ResponseMetadata?.HTTPStatusCode != 200){
      throw new Error(`Failed to complete upload - Error: ${JSON.stringify(completeUploadResp)}`);
    }
    if(completeUploadResp){
      dispatch(onUploadCompletedTrue())
    }
    // }
    let msg = `Upload Success Parts: objectName: ${filePath} | Uploads: ${JSON.stringify(uploadPartsArray.map( element => ({[element['PartNumber']]: element['PartNumber']})))}}`
    sendLog(token, msg)
    console.log('submitVideo function:  completeUpload', completeUploadResp.body)
    // }, 10 * 1000)
    // console.log('submitVideo function:  completeUpload', completeUploadResp)
    // 4. Check if object exists in S3 (double check)
    const checkObjectExists = await checkS3ObjectExists(token, BUCKETS.ORIGINAL_VIDEO_BUCKET, filePath)

    if (checkObjectExists?.body.error_code !== 0) {
      sendLog(token, `submitVideo: Object not found in S3: ${filePath}`)
      console.log('submitVideo: Object not found in S3', filePath)
      throw new Error(`submitVideo: Object not found in S3: ${filePath} , Retry Required`)
    }
    console.log("submitVideo: Upload to AWS Success.")
    return { body: filePath , status: "success" };
  } catch (err) {
    sendLog(token, err.toString())
    console.error('submitVideo: Upload to AWS Failed.', err.toString())
    return { error: err.toString(), status: "failed" }   
  }

};


export const recordRedirectStatus = (jobseekerCode, errorCode, token) => async (dispatch) => {
  return await recordRedirectStatusApi(jobseekerCode, errorCode, token);
};

export const markRetry = token => async dispatch => {
  return await markRetryApi(token)
}

export const getS3VideoStatuses = token => async dispatch => {
  let response = null
  try {
    dispatch(onGetS3VideoStatusesStarted())
    response = await getS3VideoStatusesApi(token)
    if (_.get(response, 'obj.error_code') === 0) {
      dispatch(onGetS3VideoStatusesSuccess({
        files: _.get(response, 'obj.files', []),
        s3files: _.get(response, 'obj.s3files', []),
      }))
    } else {
      dispatch(onGetS3VideoStatusesFailed())
    }
  } catch (e) {
    dispatch(onGetS3VideoStatusesFailed({
      message: e.toString(),
    }))
  } finally {
    return response
  }
}

export const submitMultiquestionAnswers = (answer, token, testIndex, questionIndex) => async dispatch => {
    let response = null
    try {
        dispatch(onSubmitMultiquestionAnswersStarted())
        response = await submitMultiquestionAnswersApi (answer, token, testIndex, questionIndex)
        if (_.get(response, 'obj.error_code') === 0) {
            dispatch(onSubmitMultiquestionAnswersSuccess())
        } else {
            dispatch(onSubmitMultiquestionAnswersFailed())
        }
    } catch (e) {
        dispatch(onSubmitMultiquestionAnswersFailed({
            message: e.toString()
        }))
    } finally {
        return response
    }
}

export const updateTestStatus = (token, currentQuestionTest) => async dispatch => {
    let response = null
    try {
        dispatch(onUpdatingTestStatus())
        response = await updateTestStatusApi (token, currentQuestionTest)
        if (_.get(response, 'obj.error_code') === 0) {
            dispatch(onUpdatingTestStatusSuccess())
        } else {
            dispatch(onUpdatingTestStatusFailed())
        }
    } catch (e) {
        dispatch(onUpdatingTestStatusFailed({
            message: e.toString()
        }))
    } finally {
        return response
    }
}

export const assignTestDeadline = (token, currentQuestionTest, testDeadline) => async dispatch => {
    let response = null
    try {
        dispatch(onSettingTestDeadline({
            currentQuestionTest: currentQuestionTest,
            testDeadline: testDeadline
        }))
        response = await assignTestDeadlineApi (token, currentQuestionTest, testDeadline)
        if (_.get(response, 'obj.error_code') === 0) {
            dispatch(onSettingTestDeadlineSuccess())
        } else {
            dispatch(onSettingTestDeadlineFailed())
        }
    } catch (e) {
        dispatch(onSettingTestDeadlineFailed({
            message: e.toString()
        }))
    } finally {
        return response
    }
}

export const updateCurrentQuestion = (token, currentQuestionTest, currentQuestion) => async dispatch => {
    let response = null
    try {
        dispatch(onUpdatingCurrentQuestion())
        response = await updateCurrentQuestionApi(token, currentQuestionTest, currentQuestion)
        if(_.get(response, 'obj.error_code') === 0) {
            dispatch(onUpdatingCurrentQuestionSuccess())
        }else {
            dispatch(onUpdatingCurrentQuestionFailed())
        }
    } catch (e) {
        dispatch(onUpdatingCurrentQuestionFailed({
            message: e.toString()
        }))
    } finally {
        return response
    }
}

export const uploadProctoringImage = (token, image, bucket_name) => async dispatch => {
    const ret = false
    console.log(image)
    try{
        //get the presigned URL
        const res = await create_s3_upload_url_simple(token, bucket_name, image.name, image, image.type)
        //upload to S3
        if (res.error_code === 0) {
            const formData = new FormData()
            Object.entries(res.msg.fields).forEach(([key, values]) => {
                formData.append(key,values);
            })
            formData.append('Content-Type', image.type)
            formData.append('file', image)
            try{
                const response = await axios.post(res.msg.url, formData, {
                    headers: {'Content-Type': 'multipart/form-data'},
                })
                return response
            } catch (err) {
                console.log(err)
            }
        } else {
            console.log("Error with api call")
        }
    } catch (err) {
        console.log(err)
    }
    return ret
}

export const updateConsentGiven = (token, consentID, actionType) => async dispatch => {
  dispatch(updatingConsentGiven())
  try {
    const response = await createConsentStatus(token, consentID, actionType)
    console.log("updateConsentGiven response: ", response)
    if (response.ok && response.obj.error_code  === 0  && response.obj.status === "success") {
      if (actionType === 'accepted') {
        dispatch(updatingConsentGivenSuccess({message: true, status: "success"}))
      } else {
        dispatch(updatingConsentGivenSuccess({message: false, status: "consent_rejected"}))
      }
    } else if (response.ok && response.obj.error_code === 5 && response.obj.status === "fail" ) {
        dispatch(updatingConsentGivenSuccess({message: false, status: "fail_non_latest"}))
    } else if (response.ok && response.obj.error_code === 6 && response.obj.status === "fail") {
        dispatch(updatingConsentGivenSuccess({message: false, status: "fail_no_consent_id"}))
    } else {
        throw new Error('something went wrong when fetching to createConsentStatus')
    }
  } catch (e) {
    console.log(5)
    dispatch(updatingConsentGivenFailed())
    console.log(e)
  }
}


export const updateCandidateInfoUploadStatus = (bool) => dispatch => {
  dispatch(updatingInfoUploadedStatus(bool))
}

export const updateNumberOfAttempts = (token, reset) => async (dispatch) => {
  const updateInterviewAttemptStatus = await postupdateNumberOfAttempts(
    token,
    reset
  );
  return updateInterviewAttemptStatus;
};


export const updateProctoringAttemptCount = (token) => async (dispatch) => {
  console.log("interview.js: proctoring count: Triggered function")
  const response = await updateVideoInterviewRetryIndex(token)
  console.log("interview.js: proctoring count: Retrieved Interview Video Retry Index Count: ", response)
  if (
    response.ok &&
    response.obj.error_code === 0
  ){
    let newAttemptNum = response.obj.video_interview_tracker_count
    dispatch(updateVideoInterviewRetryCount(newAttemptNum))
  }
}

/*
  * Function to add the record of the quota usage for the jobseeker who attempted at least one question of any test
*/
export const recordQuotaUsage = (token) => async (dispatch) => {
  try {
    const response = await recordQuotaUsageApi(token);
    if (!response.ok || response.obj.error_code !== 0) {
      return {
        status: 'fail',
        message: 'Error in recording quota usage'
      }
    }
    
    dispatch(recordFirstTestAttemptSuccess());
    return {
      status: 'success',
      message: 'Quota usage recorded successfully'
    }

  }catch (e) {
    console.log(e)
    return {
      status: 'fail',
      message: 'Error in recording quota usage'
    }
  }
}

export const {
  onJobseekerGetProfileStarted,
  onJobseekerGetProfileSuccess,
  onJobseekerGetSettingSuccess,
  onJobseekerGetProfileFailed,
  onVideoSubmissionStarted,
  onVideoSubmissionSuccess,
  onVideoSubmissionFailed,
  onJobSeekerUpdateHomeScreen,
  onJobSeekerResetHomeScreen,
  onJobSeekerUpdateEmailVerification,
  setStage,
  setVoiceIsOk,
  nextQuestion,
  setSampleRate,
  setFinishPractice,
  onGetS3VideoStatusesStarted,
  onGetS3VideoStatusesSuccess,
  onGetS3VideoStatusesFailed,
  onUploadProgress,
  onUploadCompletedTrue,
  onSubmitMultiquestionAnswersStarted,
  onSubmitMultiquestionAnswersSuccess,
  onSubmitMultiquestionAnswersFailed,
  onSettingTestDeadline,
  onSettingTestDeadlineSuccess,
  onSettingTestDeadlineFailed,
  onUpdatingTestStatus,
  onUpdatingTestStatusSuccess,
  onUpdatingTestStatusFailed,
  setCurrentTest,
  onUpdatingCurrentQuestion,
  onUpdatingCurrentQuestionSuccess,
  onUpdatingCurrentQuestionFailed,
  updatingMultiQuestionAnswers,
  updatingConsentGiven,
  updatingConsentGivenSuccess,
  updatingConsentGivenFailed,
  updatingInfoUploadedStatus,
  updateVideoInterviewRetryCount,
  updatingDefaultTriggeredStatus,
  resetDefaultTriggeredStatus,
  recordFirstTestAttemptSuccess,
} = interviewSlice.actions;

export default interviewSlice.reducer;