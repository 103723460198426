import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, Redirect } from "react-router-dom";
import Footer from './components/footer/Footer';
import { ThemeProvider } from '@material-ui/styles';
import NeufastTheme from "./theme/NeufastTheme";
import { makeStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import ProviderSetup from "./ProviderSetup";
import configureNeufastStore from "./store/configureNeufastStore";
import Home from "./pages/RecruiterHome";
import Logout from './components/login/Logout';
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import {sessionService} from 'redux-react-session'
import _ from 'lodash'
import {checkSessionToken} from './apis/session'

// Azure AD SSO imports
import SsoLogin from './components/login-sso/SsoLogin';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { setMsalConfig, defaultMsalConfig } from "./config/authConfig.dev";
import { OpenIdProvider } from "./components/login-sso/OpenIdConnectContextProvider"
import OpenIdConnectMiddleWare from './components/login-sso/OpenIdConnectMiddleware';
import OpenIdConnectLogout from './components/login-sso/OpenIdConnectLogout';

const store = configureNeufastStore();

export const msalInstance = new PublicClientApplication(defaultMsalConfig);
msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const validateSession = async (session) => {
  if (_.isEmpty(session)) {
    return true
  } else {
    const response = await checkSessionToken(session.user.interviewId, session.user.token)
    if ("body" in response) {
      const content = response.body
      console.log("content inside the validationSession: ", content)
      return content.error_code !== 0
    } else {
      console.log("jump into false block")
      return false
    }
  }
}

const options = {
  refreshOnCheckAuth: true,
  redirectPath: '/',
  driver: 'INDEXEDDB',
  validateSession
}

sessionService.initSessionService(store, options)
  .then(() => console.log("Redux React Session is ready!"))
  .catch(() => console.log("Redux React Session has error"))


export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(0),
  },
}));

const mapState = state => {
  return {
    colorScheme: state.preference.colorScheme,
  }
};

const Recruiter = lazy(() => import("./pages/Recruiter"))
const Candidate = lazy(() => import("./pages/Candidate"))
// const CandidateAutoLogin = lazy(() => import("./pages/CandidateAutoLogin"))
const Admin = lazy(() => import("./pages/Admin"))
const ResetPassword = lazy(() => import("./components/reset-password/ResetPassword"))
const GuestReview = lazy(() => import("./components/candidate-result/GuestReview"))
const RecruiterRegistration = lazy(() => import("./components/groups/RecruiterRegistration"))
const ApiDocumentations = lazy(() => import("./pages/ApiDocumentations"))


/**
 * HOC to switch between either MSAL Provider or react-oidc-context provider
 * Implementd due to observed openID token api exceptions and incorrect event
 * handling when both are active concurrently
 */
const AuthProviderWrapper = ({ children }) => {
  const location = useLocation();

  // Use react-oidc-context provider when on any openid routes
  if (location.pathname.match("/openid")) {
    return <OpenIdProvider>{children}</OpenIdProvider>;
  }

  // Fallback to MSAL Provider on all other routes
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

const Routes = () => {
  const classes = useStyles();
  const location = useLocation()

  const OpenIdConnectLoginHandler = lazy(() => import("./components/login-sso/OpenIdConnectLoginHandler"))
  const OpenIdConnectAuthorizationHandler = lazy(() => import("./components/login-sso/OpenIdConnectAuthorizationHandler"))

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: location.pathname,
      page_path: location.pathname
    })
  }, [location])

  const dummy404Page = () => {
    return (
      <>
      <center><h1>404 Page</h1></center>
      </>
    )
  }
  return (
    <Box component="main" className={classes.main}>
      <AuthProviderWrapper >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/v:apiVersion/docs" component={ApiDocumentations} />
          <Route path="/recruiter" component={Recruiter} />
          <Route path="/candidate" component={Candidate} />
          <Route path="/sso" component={SsoLogin} />
          <Route path={"/openid/login/:domain"} component={OpenIdConnectLoginHandler}/>
          <Route path={"/openid/redirect/:domain"} component={OpenIdConnectMiddleWare}/>
          <Route path={"/openid/authorize/:domain"} component={OpenIdConnectAuthorizationHandler}/>
          <Route path={"/openid/logout"} component={OpenIdConnectLogout}/>
          <Route path="/admin" component={Admin} />
          <Route path="/reset-password/:role/:token" component={ResetPassword} />
          <Route path="/guest/review/:token/:interviewLanguage" component={GuestReview} />
          <Route path="/register/:token" component={RecruiterRegistration} />
          <Route path="/logout" component={Logout}></Route>
          <Route exact path="/404" component={dummy404Page} />
          <Route render={() => <Redirect to="/404" />} />
        </Switch>
      </AuthProviderWrapper>
    </Box>
  )
}

const AppTheme = connect(mapState)(({ colorScheme }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={NeufastTheme(colorScheme)}>
        <div className={classes.root}>
          <CssBaseline />
          <Suspense fallback={null}>
            <Router>
              <Routes />
            </Router>
          </Suspense>
          <Footer />
        </div>
    </ThemeProvider>
  )
});

function App() {
  const classes = useStyles();

  return (
    <ProviderSetup store={store}>
      <AppTheme />
    </ProviderSetup>
  );
}

export default App;
