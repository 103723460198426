import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useOpenId } from "./OpenIdConnectContextProvider";
import { onUpdateOdicDomain } from "../../slices/authentication";

const OpenIdConnectMiddle = ({history, onUpdateOdicDomain}, context) => {
    /**
     * Middleware component used to set openid authority upon redirection
     * from the openid authority login page (via the redirection url) or 
     * from the /openid/login page (if previous session active)
     */
    const { domain} = useParams()

    const auth = useAuth()
    const {OPENID_STATE, state, changeTenant} = useOpenId()

    const [error, popError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(context.t("SsoLogin_OpenId_AuthorizationHandler_Error_Message_9"))

    useEffect(() => {
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addAccessTokenExpiring(() => {
            console.log("Middleware: auth: ", auth)
            auth.signinSilent();
        })
    }, [auth.events, auth.signinSilent]);

    useEffect(() => {
        console.log("Middleware: Auth Test: domain change: ", domain, " state: ", state, " user: ", auth.user)
        if(state !== OPENID_STATE.READY){
            changeTenant(domain)
        }
        if(state === OPENID_STATE.READY && auth.user){
            /**
             * Set redux state to track current openid authority, &
             * redirect to account Authorization Page after the correct 
             * authority has been loaded.
             */
            onUpdateOdicDomain(domain)
            history.push(`/openid/authorize/${domain}`)
        }
    }, [domain, state, auth.user])

    return (
        <>
        </>
    )
}


const mapState = state => {
    return {
    }
}

const mapDispatch = {
    onUpdateOdicDomain
}
OpenIdConnectMiddle.contextTypes = {
    t: PropTypes.func
}

export default withRouter(connect(mapState, mapDispatch)(OpenIdConnectMiddle));