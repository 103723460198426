export const kbtgTenant = {
    authority: "https://aucayigrq.accounts.ondemand.com/oauth2",
    // authority: "https://aucayigrq.accounts.ondemand.com/oauth2/authorize",
    client_id: "3ec58f17-c85e-4f7e-aee5-58a40bb929bd",
    redirect_uri: "https://interview-uat.neufast.com/openid/redirect/kbtg",
    metadata: {
        authorization_endpoint: "https://aucayigrq.accounts.ondemand.com/oauth2/authorize",
        token_endpoint: "https://aucayigrq.accounts.ondemand.com/oauth2/token",
        userinfo_endpoint: "https://aucayigrq.accounts.ondemand.com/oauth2/userinfo",
    }
}